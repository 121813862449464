import { getSearchedGoods,getNewGoods, getBanners, getShippingSectionDetail } from '@/api/home'

export const actions = {
    async FETCH_SHIPPING_BANNERS({ commit }) {
        try {
            let result = await getBanners('SHIPPING');
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('SET_SHIPPING_BANNERS', result.data.data);
            }
        }
        catch (error) {
            console.error('FETCH_SHIPPING_BANNERS', error);
        }
    },
    async FETCH_SHIPPING_DETAIL_BANNERS({ commit }) {
        try {
            let result = await getBanners('ITEM_DETAIL');
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('SET_SHIPPING_DETAIL_BANNERS', result.data.data);
            }
        }
        catch (error) {
            console.error('FETCH_SHIPPING_DETAIL_BANNERS', error);
        }
    },
    async FETCH_SHIPPING_GOODS({ state, rootState, commit, dispatch }, { category, page, tags }) {
        let result = undefined;
        try {
            result = await getSearchedGoods({
                lat : rootState.geolocation.latitude,
                lng : rootState.geolocation.longitude,
                sort_type: state.shippingFilter.sort_type,
                // is_coupon: state.shippingFilter.is_coupon ? true : undefined,
                status: 1,
                pickup_method : 'S',
                category: category != 0 ? category : undefined,
                tags,
                page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_SHIPPING_ITEM', result.data.data);
                commit('SET_SHIPPING_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_SHIPPING_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_SHIPPING_GOODS', error);
        }

        return result;
    },
    async FETCH_NEW_SHIPPING_GOODS({ rootState, commit }, { page }) {
        let result = undefined;
        try {
            result = await getNewGoods({
                page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_SHIPPING_ITEM', result.data.data);
                commit('SET_SHIPPING_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_SHIPPING_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_SHIPPING_GOODS', error);
        }

        return result;
    },
    async FETCH_BESTS({ commit }) {
        let result = undefined;
        try {
            result = await getShippingSectionDetail(3);
            if(result.data.code == 'S0000' && result.data.data.items != undefined) {
                commit('APPEND_SHIPPING_ITEM', result.data.data.items);
            }
        }
        catch (error) {
            console.error('FETCH_SHIPPING_GOODS', error);
        }

        return result;
    },
    async FETCH_SHIPPING_TAGS({ state, rootState, commit }, { page, tags }) {
        let result = undefined;
        try {
            result = await getSearchedGoods({
                lat : rootState.geolocation.latitude,
                lng : rootState.geolocation.longitude,
                sort_type: state.shippingFilter.sort_type,
                // is_coupon: state.shippingFilter.is_coupon ? true : undefined,
                status: 1,
                pickup_method : 'S',
                tags,
                page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_SHIPPING_ITEM', result.data.data);
                commit('SET_SHIPPING_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_SHIPPING_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_SHIPPING_GOODS', error);
        }

        return result;
    },

}