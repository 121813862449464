<template>
    <aside
        id="bottom-navigation"
        class="tab-wrapper"
    >
        <ul class="tab-area space-around">
            <router-link
                v-for="menu in navMenus"
                :key="menu.name"
                class="tab"
                tag="li"
                :to="{ path: menu.path }"
            >
                <img
                    class="tab-icon"
                    :src="$route.matched[0].path == menu.path.split('?')[0] ? menu.activeImage : menu.image"
                    :alt="menu.name"
                >
                <label :class="{ active : $route.path == menu.path }">
                    {{ menu.name }}
                </label>
            </router-link>
        </ul>
    </aside>
</template>

<script>
export default {
    data() {
        return {
            navMenus: [
                {
                    name: '카테고리',
                    path: '/aside',
                    image: 'https://i.lastorder.in/assets/v2/icon/bottom-menu.png',
                    activeImage: 'https://i.lastorder.in/assets/v2/icon/bottom-menu-active.png',
                },
                {
                    name: '검색',
                    path: '/search',
                    image: 'https://i.lastorder.in/assets/v2/icon/bottom-search.png',
                    activeImage: 'https://i.lastorder.in/assets/v2/icon/bottom-search-active.png'
                },
                {
                    name: '홈',
                    path: '/market',
                    image: 'https://i.lastorder.in/assets/v2/icon/bottom-home.png',
                    activeImage: 'https://i.lastorder.in/assets/v2/icon/bottom-home-active.png',
                },
                {
                    name: '마이페이지',
                    path: '/mypage',
                    image: 'https://i.lastorder.in/assets/v2/icon/bottom-my.png',
                    activeImage: 'https://i.lastorder.in/assets/v2/icon/bottom-my-active.png'
                },
                {
                    name: '최근본상품',
                    path: '/like?tab=recent',
                    image: 'https://i.lastorder.in/assets/v2/icon/bottom-recent.png',
                    activeImage: 'https://i.lastorder.in/assets/v2/icon/bottom-recent-active.png'
                }
            ]
        }
    },
    methods: {
        goToRoute(path) {
            if(path != this.$route.path) {
                try {
                    this.$router.push(path);
                }
                catch (error) {
                    console.error('goToRoute', error);
                }
            }
            else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
        }
    }
}
</script>

<style scoped lang="scss">
.tab-wrapper {
    background: #fff;
    border-top: 1px solid #eee;
    z-index: 2000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    .tab-area {
        background: #fff;
        box-sizing: border-box;
        width: 100%;
        max-width: 750px;
        margin: 0 auto;
        display: flex;
        height: 52px;
        .tab {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 36px;
            width: 20%;
            .tab-icon {
                width: 24px;
                height: 24px;
            }
            label {
                font-size: 10px;
                color: #949494;
                &.active {
                    color: #292929;
                }
            }
        }
    }
}
</style>