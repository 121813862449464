export const mutations = {
    SET_SHOPPING_CART_BANNERS(state, data) {
        state.shoppingCart1Banners = [];

        data.forEach((banner)=> {
            if(banner.position == 'SHOPPING_CART_1')
                state.shoppingCart1Banners.push(banner);
        })

        state.shoppingCart1Banners.sort((a, b)=> a.sort - b.sort);
    },
    SET_CART_SHOPS(state, data) {
        state.shopCarts = data;
    },
    SET_COUPON_OPTIMIZED(state, value) {
        state.couponOptimized = value;
    },
    SET_CART_SUMMARY(state, value) {
        state.cartSummary = value;
    },
    SET_SELECTED_PICKTYPE(state, value) {
        state.selectedPickType = value;
    },
    SET_FORM_PICKUP_TYPE(state, value) {
        state.pickupType = value;
        if(value.includes('P')) {
            state.selectedPickType = 'P'
        }
        else if(value.includes('E')) {
            state.selectedPickType = 'E'
        }
        else if(value.includes('D')) {
            state.selectedPickType = 'D'
        }
        else {
            state.selectedPickType = 'S'
        }
    },
    SET_ORDER_FORM(state, data) {
        state.orderForm = data;
    },
    SET_COUPON_COUNT(state, value){
        state.orderForm.order_coupon_count = value;
    },
    SET_POINT_VALUE(state, value){
        state.pointValue = value;
    },
    INIT_CHECKED_ARRAY(state, data) {
        state.checkedShops = [];
        data.forEach(shop => {
            let bundles = [];
            shop.bundles.forEach(bundle => {
                let items = [];
                bundle.items.forEach(item => {
                    items.push({
                        id: item.id,
                        checked: true,
                    });
                })
                bundles.push({ items })
            })
            state.checkedShops.push({
                checked: true,
                bundles
            })
        });
    },
    APPLY_COUPON(state, value) {
        state.orderCouponId = value.id;
        state.orderCouponPrice = value.price;
        state.orderCouponName = value.name
    },
    REMOVE_COUPON(state) {
        state.orderCouponId = undefined;
        state.orderCouponPrice = 0;
        state.orderCouponName = ''
    },
    SET_ORDER_SHIPPING(state, value){
        if(state.orderForm.shippings)
            Object.assign(state.orderForm.shippings, value);
        else
            state.orderForm.shippings = value
    },
}