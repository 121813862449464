import { instanceAuth } from './index';

/**
 * 배송상품 카트 목록 가져오기
 * @returns response
 */
export const getMarketCart = () => {
    return instanceAuth().get('/v1/web/cart/market/');
}

/**
 * 장바구니 상품 갯수 합계
 * @returns response
 */
export const getCartCount = ()=> {
    return instanceAuth().get('/v1/web/cart/summary/');
}

/**
 * 장바구니 담기
 * @param {Object} json option json
 * @param {Object} params query parameters
 * @returns response
 */
export const addToCart = (json, params)=> {
    return instanceAuth().post('/v1/web/cart/', json, {
        headers : { 'Content-Type': 'application/json' },
        params,
    });
}

/**
 * 장바구니 수정
 * @param {*} id cart id
 * @param {*} json cart json
 * @returns response
 */
export const updateCartItem = (id, json)=> {
    return instanceAuth().put(`/v1/web/cart/${id}/`, json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 장바구니 삭제
 * @param {*} data cart data
 * @returns response
 */
export const deleteCartItem = (data)=> {
    return instanceAuth().delete(`/v1/web/cart/`, {
        headers : { 'Content-Type': 'application/json' },
        data
    });
}

/**
 * 배송상품 장바구니 쿠폰 최적화
 * @returns response
 */
export const optimizeMarketCoupon = (params)=> {
    return instanceAuth().put(`/v1/web/cart/market/optimize/`, null, { params });
}

/**
 * 장바구니 내 상품쿠폰 목록
 * @param {*} cartId cart id
 * @param {*} params query parameters
 * @returns response
 */
export const getCartCoupons = (cartId, params) => {
    return instanceAuth().get(`/v1/web/cart/${cartId}/coupon/`, { params });
}

/**
 * 장바구니 -> 주문서 주문상품 리스트
 * @param {*} json
 * @returns response
 */
export const requestPurchaseForm = (json)=> {
    return instanceAuth().post('/v1/purchase/cart/', json, {
        headers : { 'Content-Type': 'application/json' }
    });
}

/**
 * 일반상품 카트 목록 가져오기
 * @returns response
 */
export const getNormalCart = () => {
    return instanceAuth().get('/v1/web/cart/lastorder/');
}

/**
 * 일반상품 장바구니 쿠폰 최적화
 * @returns response
 */
export const optimizeNormalCoupon = (params)=> {
    return instanceAuth().put(`/v1/web/cart/lastorder/optimize/`, null, { params });
}
