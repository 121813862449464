import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const goods = {
    state: {
        pagination: {
            page: 0,
            has_next: false,
        },
        isPhotoReview: false,
        reviews: [],
        reviewTotal: 0,
        selectedTab: "first",

        shipping: false,
        product: {
            name: "",
            item_image: [],
            like: undefined,
            shop: {},
            discount_log: {},
            shipping_template: {},
        },
        listImages: [],
        detailImages: [],
        theOthers: [],
        isChargeCombined: false,
        combinedShipping: [],
        combinedPagination: {
            page: 0,
            has_next: false,
        },
    },
    getters,
    mutations,
    actions,
};
