import { instance, instanceAuth } from './index';

/**
 * 상점 상세 가져오기
 * @param {*} shopId shop index
 * @param {*} params query parameters
 * @param {*} isLoggedIn whether to log in
 * @returns response
 */
export const getShopDetail = (shopId, params, isLoggedIn = false) => {
    let axiosInstance = isLoggedIn ? instanceAuth() : instance();
    return axiosInstance.get(`/v2/web/shop/${shopId}/`, { params });
}

/**
 * 가게 내 태그 리스트
 * @param {*} shop_id shop id
 * @param {*} params query parameters
 * @returns response
 */
export const getShopTags = (shop_id, params) => {
    return instance().get(`/v1/web/shop/${shop_id}/tags/`, { params });
}

/**
 * 상점내 판매상품 목록 가져오기
 * @param {*} shopId shop index
 * @param {*} params query parameters
 * @param {*} isLoggedIn whether to log in
 * @returns response
 */
export const getShopItemsForSale = (shopId, params, isLoggedIn) => {
    let axiosInstance = isLoggedIn ? instanceAuth() : instance();
    return axiosInstance.get(`/v1/web/shop/${shopId}/item/`, { params });
}

/**
 * 상점 리뷰 가져오기
 * @param {*} shopId shop index
 * @param {*} params query parameters
 * @returns response
 */
export const getShopReviews = (shopId, params) => {
    return instance().get(`/v1/web/shop/${shopId}/reviews/`, { params });
}

/**
 * 상점 쿠폰 모두받기
 * @param {String} shopId shop index
 * @returns response
 */
export const downloadAllShopCoupons = (shopId) => {
    return instanceAuth().post(`/v1/web/shop/${shopId}/coupon/`);
}