export const mutations = {
    SET_IS_SHIPPING(state, value) {
        state.shipping = value;
    },
    SET_PRODUCT(state, value) {
        Object.assign(state.product, value);
        if (!value.like) state.product.like = undefined;
    },
    RESET_PRODUCT(state) {
        state.product = {
            name: "",
            item_image: [],
            like: undefined,
            shop: {},
            discount_log: {},
            shipping_template: {},
        };
    },
    SET_LIST_IMAGES(state, value) {
        state.listImages = value;
    },
    SET_DETAIL_IMAGES(state, value) {
        state.detailImages = value;
    },
    SET_IS_GOODS_PHOTO_REVIEW(state, data) {
        state.isPhotoReview = data;
    },

    SET_THE_OTHERS(state, data) {
        state.theOthers = data;
    },
    SET_GOODS_COMBINED_PAGINATION(state, value) {
        Object.assign(state.combinedPagination, value);
    },
    SET_COMBINED_SHIPPING(state, data) {
        state.combinedShipping = data;
    },
    APPEND_GOODS_COMBINED(state, data) {
        data.forEach((item) => {
            state.combinedShipping.push(item);
        });
    },
    SET_IS_CHARGE_COMBINED(state, value) {
        state.isChargeCombined = value;
    },

    SET_GOODS_REVIEW_PAGINATION(state, value) {
        Object.assign(state.pagination, value);
    },
    SET_GOODS_REVIEWS(state, data) {
        state.reviews = data;
    },
    APPEND_GOODS_REVIEWS(state, data) {
        data.forEach((item) => {
            state.reviews.push(item);
        });
    },
    SET_REVIEW_TOTAL(state, data) {
        state.reviewTotal = data;
    },
    SET_GOODS_SELECTED_TAB(state, data) {
        state.selectedTab = data;
    },
};
