export const mutations = {
    SET_LIKE_PAGINATION(state, value) {
        Object.assign(state.pagination, value);
    },
    SELECTED_TAB_CHANGED(state, value) {
        state.selectedTab = value;
    },
    SET_LIKE_TOTAL(state, value) {
        state.likeTotal = value;
    },
    SET_LIKESHOPS_TOTAL(state, value) {
        state.likeShopsTotal = value;
    },

    // likeitems
    SET_LIKE_ITEMS(state, data) {
        state.likeItems = data;
    },
    APPEND_LIKE_ITEMS(state, data) {
        data.forEach(item => {
            state.likeItems.push(item);
        });
    },
    REMOVE_LIKE_ITEM(state, index) {
        state.likeItems.splice(index, 1);
    },
    SET_LIKE_ITEM_ALRAM(state, { index, is_notification }){
        Object.assign(state.likeItems[index], {
            is_notification: is_notification,
        });
    },

    // likeshops
    SET_LIKE_SHOPS(state, data) {
        state.likeShops = data;
    },
    APPEND_LIKE_SHOPS(state, data) {
        data.forEach(item => {
            state.likeShops.push(item);
        });
    },
    REMOVE_LIKE_SHOP(state, index) {
        state.likeShops.splice(index, 1);
    },

    // recentitems
    SET_RECENT_ITEMS(state, data) {
        state.recentItems = data;
    },
    APPEND_RECENT_ITEMS(state, data) {
        data.forEach(item => {
            state.recentItems.push(item);
        });
    },
    REMOVE_RECENT_ITEM(state, index) {
        state.recentItems.splice(index, 1);
    },

}