import { getCoupons } from '@/api/mypage'

export const actions = {
    async FETCH_CART_COUPONS({ commit }, page) {
        let result;
        try {
            result = await getCoupons({
                position_type: 'CART',
                page: page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_CART_COUPONS', result.data.data);
                commit('SET_CART_TOTAL', result.data.count);
                commit('SET_CART_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_CART_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        } catch (error) {
            console.error('getCouponsAsync', error);
        }

        return result;
    },
    async FETCH_ORDER_COUPONS({ commit }, page) {
        let result;
        try {
            result = await getCoupons({
                position_type: 'ORDER',
                page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_ORDER_COUPONS', result.data.data);
                commit('SET_ORDER_TOTAL', result.data.count);
                commit('SET_ORDER_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_ORDER_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        } catch (error) {
            console.error('getCouponsAsync', error);
        }

        return result;
    },
}