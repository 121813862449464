export const mutations = {
    TAB_SELETED(state, data) {
        state.selectedTab = data;
    },
    SET_PURCHASED_LIST(state, data) {
        state.purchasedList = data;
    },
    APPEND_PURCHASED_LIST(state, data) {
        data.forEach(item => {
            state.purchasedList.push(item);
        });
    },
    SET_PURCHASED_ITEM(state, {index, data}){
        Object.assign(state.purchasedList[index], data);
    },
    SET_PURCHASED_SHIPPING_GROUP(state, { orderIndex, shippingIndex, data }) {
        Object.assign(state.purchasedList[orderIndex].shipping_group[shippingIndex], data);
    },
    SET_SHOP_LOCATION(state, value) {
        state.shopLocation = value;
    },
    SET_PURCHASE_PAGINATION(state, value) {
        Object.assign(state.pagination, value);
    },
    SET_PURCHASED_LIST_FILTERS(state, value) {
        Object.assign(state.purchaseHistoryFilters, value);
    },
    SET_IS_FILTERED_RESULT(state, value) {
        state.isFilteredResult = value;
    },
    SET_SELECTED_DATE_FILTERS(state, value) {
        state.selectedDateFilterIndex = value;
    },

    SET_PURCHASED_ORDER(state, value) {
        Object.assign(state.purchasedOrder, value);
    },
}