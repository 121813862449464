export const mutations = {
    SET_EVENTS(state, data) {
        state.events = data;
    },
    APPEND_EVENTS(state, data) {
        data.forEach(item => {
            state.events.push(item);
        });
    },

    SET_EXHIBITIONS(state, data) {
        state.exhibitions = data;
    },
    APPEND_EXHIBITIONS(state, data) {
        data.forEach(item => {
            state.exhibitions.push(item);
        });
    },


    SET_TITLE(state, data) {
        state.pageTitle = data;
    },
    SET_IMAGE(state, data) {
        state.pageImage = data;
    },
    SET_ROW_COUNT(state, data) {
        state.rowCount = data;
    },

    SET_EXHIBITION_DETAIL(state, data) {
        state.exhibitionDetails = data;
    },
    APPEND_EXHIBITION_DETAIL(state, data) {
        data.forEach(item => {
            state.exhibitionDetails.push(item);
        });
    },

    SET_PAGINATION(state, value) {
        Object.assign(state.pagination, value);
    },
    SET_DETAIL_PAGINATION(state, value) {
        Object.assign(state.detailPagination, value);
    },

    SET_EXHIBITION_FILTER(state, filter) {
        Object.assign(state.exhibitionFilter, filter);
    },
}