import ToastMessage from './ToastMessage.vue'

const Constructor = (Vue, globalOptions = {}) => {
    return {
        open(options) {
            let message;
            if (Array.isArray(options))
                message = options;

            const defaultOptions = {
                message
            };

            const propsData = Object.assign({}, defaultOptions, globalOptions, options);

            return new (Vue.extend(ToastMessage))({
                el: document.createElement('div'),
                propsData
            })
        },
    }
};

export default Constructor;