import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const home = {
    state: {
        topBanners: [],
        middleBanners: [],
        bottomBanners: [],
        notice: [],
    },
    getters,
    mutations,
    actions,
}