import { getBanners } from '@/api/home'

export const actions = {
    async GET_HOME_BANNERS({ commit }) {
        try {
            let result = await getBanners('MARKET_MAIN');
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                // 1회라도 노출 되었는지 여부 값 추가
                let banners = result.data.data;
                banners.forEach(element => {
                    element.is_shown = false;
                });
                commit('SET_HOME_BANNERS', result.data.data);
            }
        }
        catch (error) {
            console.error('GET_HOME_BANNERS', error);
        }
    },
    async GET_NOTICES({ commit }) {
        try {
            let result = await getBanners('MARKET_POP');
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('SET_NOTICES', result.data.data);
            }
        }
        catch (error) {
            console.error('GET_NOTICES', error);
        }
    },
}