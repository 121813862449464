import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { actions } from "./actions"
import { mutations } from "./mutations"
import { getters } from "./getters"
import { modules } from "./modules"
const Configuration = require('@/configuration')
let stage = Configuration.value('vueAppEnv')
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        uuid: undefined,
        serviceAvailable: true,
        isPopStateDetected: false,
        showAppBanner: true,
        user: {
            token_type: 'Bearer',
            access_token : undefined,
            expires_in: 0,
            scope: undefined,
            member: {
                id: undefined,
                mobile: undefined,
                name: undefined,
                is_adult_verification: false,
            }
        },                                  // 사용자 정보
        cartCount: {
            market: 0,
            normal: 0,
        },                      // 장바구니 담긴 갯수
        geolocation : {
            latitude: '37.4842680361482',
            longitude: '126.929745374123',
            address_name: '',
            address_extra: '',
            place_name: '신림동',
        },                                  // 현재 설정위치
        filter: {
            status: true,
        },
        recentSearchWords : [],             // 최근검색어
        recentSearchLocations: [],          // 최근검색 지역
        pickType : [],
        memberType : [],
        categories: [],                     // 카테고리
        shippingCategories: [],             // 배송 카테고리
        distanceOptions: [
            { label: '전체', value: 1000000 },
            { label: '2km', value: 2000 },
            { label: '5km', value: 5000 },
            { label: '10km', value: 10000 },
            { label: '15km', value: 15000 },
            { label: '20km', value: 20000 },
        ],
        shippingSubMenus: [
            {
                name: '홈',
                path: '/market/home'
            },
            {
                name: "구해주세요",
                value:
                    stage == "prod" || stage == "stage"
                        ? "/tag/166"
                        : "/tag/449",
                path:
                    stage == "prod" || stage == "stage"
                        ? "/tag/166"
                        : "/tag/449",
                order: 1,
                fixed: true,
            },
            // {
            //     name: '명절선물',
            //     value: 'new-year-gifts',
            //     path: (stage == 'prod' || stage == 'stage')? '/tag/295' : '/tag/480',
            //     fixed: true
            // },
            {
                name: "최저가보장",
                value:
                    stage == "prod" || stage == "stage"
                        ? "/tag/169"
                        : "/tag/452",
                path:
                    stage == "prod" || stage == "stage"
                        ? "/tag/169"
                        : "/tag/452",
                fixed: true,
            },
            {
                name: "무료배송",
                value:
                    stage == "prod" || stage == "stage"
                        ? "/tag/165"
                        : "/tag/448",
                path:
                    stage == "prod" || stage == "stage"
                        ? "/tag/165"
                        : "/tag/448",
                fixed: true,
            },
            {
                name: '리퍼관',
                path: (stage == 'prod' || stage == 'stage')? '/tag/303' : '/tag/595',
                value: (stage == 'prod' || stage == 'stage')? '/tag/303' : '/tag/595',
                order: 7,
                fixed: true
            },
            {
                name: "베스트",
                value: "/market/bests",
                path: "/market/bests",
                fixed: true,
            },
            {
                name: "NEW",
                value: "new-arrivals",
                path: "/market/new-arrivals",
                fixed: true,
            },
            {
                name: "이벤트",
                value: "events",
                path: "/market/events",
                fixed: true,
            },
            {
                name: "기획전",
                value: "exhibitions",
                path: "/market/exhibitions",
                order: 7,
                fixed: true,
            },
            {
                name: "전체",
                value: 0,
                path: "/market/category?category=0",
                order: 7,
                fixed: true,
            },
        ],
        // 현재 탭의 마켓/일반 구분
        isMarket: true,
    },
    modules,
    getters,
    mutations,
    actions,
    plugins: [createPersistedState({
        paths : [
            'user',
            'uuid',
            'geolocation',
            'recentSearchWords',
            'recentSearchLocations',
        ]
    })],
});