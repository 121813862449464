export const mutations = {
    SET_SHIPPING_PAGINATION(state, value) {
        Object.assign(state.pagination, value);
    },
    SET_SHIPPING_BANNERS(state, data) {
        state.topBanners = [];
        state.midBanners =[];

        data.forEach((banner)=> {
            if(banner.position == 'SHIPPING_2')
                state.midBanners.push(banner);
            else
                state.topBanners.push(banner);
        })

        state.topBanners.sort((a, b)=> a.sort - b.sort);
        state.midBanners.sort((a, b)=> a.sort - b.sort);
    },
    SET_SHIPPING_DETAIL_BANNERS(state, data) {
        state.shippingDetail1Banners = [];

        data.forEach((banner)=> {
            if(banner.position == 'ITEM_DETAIL_1')
                state.shippingDetail1Banners.push(banner);
        })
        state.shippingDetail1Banners.sort((a, b)=> a.sort - b.sort);
    },
    SET_SHIPPING_ITEMS(state, data) {
        data.forEach(item => {
            state.shippingItems.push(item);
        });
    },
    APPEND_SHIPPING_ITEM(state, data) {
        data.forEach(item => {
            state.shippingItems.push(item);
        });
    },
    REMOVE_SHIPPING_ITEM(state) {
        state.shippingItems = [];
    },

    SET_SHIPPING_FILTER(state, filter) {
        Object.assign(state.shippingFilter, filter);
    },
    RESET_SHIPPING_FILTER(state) {
        Object.assign(state.shippingFilter, {
            sort_type: 8,
            is_coupon: false,
        });
    },

    SET_TAG_TITLE(state, data) {
        state.tagTitle = data;
    },
    SET_TAG_DESCRIPTION(state, data) {
        state.tagDescription = data;
    },
}