import {
    getFavoriteItems,
    getFavoriteShops,
    getRecentItems,
} from '@/api/like'

export const actions = {
    async FETCH_FAVORITE_ITEMS({ rootState, commit }, { page }) {
        let result = undefined;
        try {
            result = await getFavoriteItems({
                lat: rootState.geolocation.latitude,
                lng: rootState.geolocation.longitude,
                delivery_type: 'S',
                page: page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_LIKE_ITEMS', result.data.data);
                commit('SET_LIKE_TOTAL', result.data.count);
                commit('SET_LIKE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_LIKE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        } catch (error) {
            console.error('FETCH_FAVORITE_ITEMS', error);
        }

        return result
    },
    async FETCH_FAVORITE_SHOPS({ rootState, commit }, { page }) {
        let result = undefined;
        try {
            result = await getFavoriteShops({
                lat: rootState.geolocation.latitude,
                lng: rootState.geolocation.longitude,
                delivery_type: 'S',
                page: page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_LIKE_SHOPS', result.data.data);
                commit('SET_LIKESHOPS_TOTAL', result.data.count);
                commit('SET_LIKE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_LIKE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        } catch (error) {
            console.error('FETCH_FAVORITE_SHOPS', error);
        }

        return result;
    },
    async FETCH_RECENT_ITEMS({ commit }, { page }) {
        let result;
        try {
            result = await getRecentItems({
                delivery_type: 'S',
                page: page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_RECENT_ITEMS', result.data.data);
                commit('SET_LIKE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_LIKE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        } catch (error) {
            console.error('FETCH_RECENT_ITEMS', error);
        }
        return result;
    }
}