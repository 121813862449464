import {
    getShopDetail,
    getShopItemsForSale,
    getShopReviews,
    getShopTags,
} from "@/api/shop";
import { imageTypeSelector } from "@/plugins/utils";

export const actions = {
    async FETCH_SHOP_DETAIL({ rootState, commit }, { shopId, query }) {
        try {
            let result = await getShopDetail(
                shopId,
                query,
                rootState.user.access_token ? true : false
            );
            if (result.data.code == "S0000" && result.data.data != undefined) {
                commit("SET_SHOP", result.data.data);
                commit(
                    "SET_SHOP_IMAGES",
                    imageTypeSelector("shop", result.data.data.shop_image)
                );
                commit(
                    "SET_SHOP_PLACE_ORIGIN_IMAGES",
                    imageTypeSelector(
                        "place_origin",
                        result.data.data.shop_image
                    )
                );

                // // 배달정보 판단
                // if(result.data.data.pickup_type.includes('D'))
                //     dispatch('FETCH_SHOP_DELIVERY_INFO', shopId);

                // 배송업체 판단
                commit(
                    "SET_IS_SHIPPING",
                    result.data.data.pickup_type.includes("S") ? true : false
                );
            }
        } catch (error) {
            console.error("FETCH_SHOP_DETAIL", error);
            throw error;
        }
    },
    async FETCH_SHOP_REVIEWS({ state, commit }, { id, page }) {
        let result;
        try {
            result = await getShopReviews(id, {
                is_exist_image: state.isPhotoReview ? true : undefined,
                page: page,
            });
            if (result.data.code == "S0000") {
                commit("SET_REVIEW_TOTAL", result.data.count);
                commit("SET_REVIEW_PAGINATION", {
                    page: result.data.page,
                    has_next: result.data.has_next,
                });

                if (result.data.data != undefined) {
                    commit("APPEND_REVIEWS", result.data.data);
                }
            }
        } catch (error) {
            console.error("FETCH_SHOP_REVIEWS", error);
        }

        return result;
    },
    async FETCH_SHOP_ITEMS_FOR_SALE({ state, commit, rootState }, params) {
        let result;
        try {
            result = await getShopItemsForSale(
                params.id,
                params,
                rootState.user.access_token ? true : false
            );
            if (result.data.code == "S0000") {
                if (params.goods_type == "N") {
                    commit("SET_TOTAL", { common: result.data.count });
                    commit("SET_COMMONITEM_PAGINATION", {
                        page: result.data.page,
                        has_next: result.data.has_next,
                    });
                } else {
                    commit("SET_TOTAL", { discount: result.data.count });
                    commit("SET_PAGINATION", {
                        page: result.data.page,
                        has_next: result.data.has_next,
                    });
                }

                if (result.data.data != undefined) {
                    commit(
                        params.goods_type == "N"
                            ? "APPEND_COMMON_ITEMS"
                            : "APPEND_DISCOUNT_ITEMS",
                        result.data.data
                    );
                }
            }
        } catch (error) {
            console.error("FETCH_SHOP_ITEMS_FOR_SALE", error);
        }
    },
    async FETCH_SHOP_ITEMS_EXCLUDE({ rootState }, { id, exclude }) {
        let result;
        try {
            result = await getShopItemsForSale(
                id,
                { exclude },
                rootState.user.access_token ? true : false
            );
        } catch (error) {
            console.error("FETCH_SHOP_ITEMS_EXCLUDE", error);
        }
        return result;
    },
    async FETCH_SHOP_ITEMS_COMBINED(
        { rootState },
        { id, shipping_template_id, exclude, page = 1 }
    ) {
        let result;
        try {
            result = await getShopItemsForSale(
                id,
                { shipping_template_id, exclude, page, per_page: 10 },
                rootState.user.access_token ? true : false
            );
        } catch (error) {
            console.error("FETCH_SHOP_ITEMS_COMBINED", error);
        }
        return result;
    },
    async FETCH_SHOP_TAGS({ commit }, { id }) {
        commit("SET_SHOP_TAGS", []);
        try {
            let { data } = await getShopTags(id);
            if (data.code == "S0000" && data.data != undefined) {
                commit("SET_SHOP_TAGS", data.data);
            }
        } catch (error) {
            console.error("FETCH_SHOP_TAGS", error);
        }
    },
};
