import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export const cart = {
    state: {
        shoppingCart1Banners:[],
        couponOptimized: false,
        shopCarts: [],
        cartSummary: [
            {
                delivery_type: 'N',
                item_count: 0,
            },
            {
                delivery_type: 'S',
                item_count: 0,
            }
        ],
        selectedPickType: undefined,
        pickupType: [],
        checkedShops: [],
        deliveryPriceSummary: {
            delivery_charge : 0,
            delivery_charge_extra : 0,
            delivery_total : 0,
        },

        // order Form
        orderForm: {
            id: null,
            mobile: '',
            name: null,
            order_coupon_count: 0,
            pickup_time_list:[],
            point_total: 0,
            shippings: {
                title: undefined,
                receipt_name: undefined,
                receipt_cp: undefined,
                address: undefined,
                address_extra: undefined,
                comment: undefined,
                extra_charge_type: 0,
            }
        },

        orderCouponId: undefined,
        orderCouponName: undefined,
        orderCouponPrice: 0,
        pointValue: 0,
    },
    actions,
    mutations,
    getters,
}