import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const shop = {
    state: {
        pagination: {
            page: 0,
            has_next: false,
        },
        commonitemPagination: {
            page: 0,
            has_next: false,
        },
        reviewPagination: {
            page: 0,
            has_next: false,
        },
        isCvs: false,
        isPhotoReview: false,
        status: true,
        selectedTab: 'first',
        isShipping: false,
        selectedTag: 0,
        activeCategory: 0,
        shop : {
            favorite: undefined,
            franchisee: {},
            holiday: [],
            is_already_download: false,
        },
        reviews : [],
        discountItem: [],
        commonItems: [],
        categorySummaries : [],
        shopTags: [],
        reviewTotal: 0,
        total: {
            discount: 0,
            common: 0,
        },
        isAvailableDelivery: false,
        shopImages: [],
        placeOriginImages: [],
        shopDeliveryInfomation: {},

        searchResult: false,
        searchKeyword: '',
    },
    mutations,
    actions,
    getters,
}