export const getters = {
    getRecentSearchWords: state => state.recentSearchWords,
    getRecentSearchLocations: state => state.recentSearchLocations,
    getShippingSubMenus: state => state.shippingSubMenus,
    getShippingCategories: state => state.shippingCategories,
    getTokenType: state => state.user.token_type,
    getAccessToken: state => state.user.access_token,
    getUserName: state => state.user.member.name,
    getUserId: state => state.user.member.id,
    getIsAdultVerified: state => state.user.member.is_adult_verification,
    getUUID: state => state.uuid,
    getIsMarket: state => state.isMarket,
}