import { mutations } from './mutations'
import { actions } from './actions'

export const exhibition = {
    state: {
        // layout pagination
        pagination: {
            page: 1,
            has_next: false,
        },
        // detail pagination
        detailPagination: {
            page: 1,
            has_next: false,
        },

        activeItem: 0,
        events: [],// event
        exhibitions: [],// exhibition

        pageTitle: '기획전',
        pageImage: undefined,
        rowCount: 1,

        exhibitionDetails: [],// exhibition detail

        // detail filter
        exhibitionFilter: {
            sort_type: 3,
            distance: 1000000,
        },
    },
    actions,
    mutations,
}