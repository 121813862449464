import { instance, instanceAuth } from './index';

/**
 * 배너 목록 가져오기
 * @param {*} locateCode banner location code
 * @returns response
 */
export const getBanners = (locateCode) => {
    return instance().get(`/v1/web/banners/${locateCode}/`);
}

/**
 * 자동완성 목록 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getAutocompleteList = (params) => {
    return instance().get('/v1/web/es/autocomplete/', { params });
}

/**
 * 내주변 상점목록 및 상점검색
 * @param {*} params query parameters
 * @param {*} isLoggedIn whether to log in
 * @returns response
 */
export const getShopsAroundMe = (params, isLoggedIn = false) => {
    let axiosInstance = isLoggedIn ? instanceAuth() : instance();
    return axiosInstance.get('/v1/web/shops/', { params });
}

/**
 * 상품검색 및 배송상품 목록 불러오기
 * @param {*} params query parameters
 * @param {*} isLoggedIn whether to log in
 * @returns response
 */
export const getSearchedGoods = (params, isLoggedIn = false) => {
    let axiosInstance = isLoggedIn ? instanceAuth() : instance();
    return axiosInstance.get('/v1/web/items/', { params });
}
/**
 * 상품검색 및 배송상품 목록 NEW상품 불러오기
 * @param {*} params query parameters
 * @param {*} isLoggedIn whether to log in
 * @returns response
 */
export const getNewGoods = (params, isLoggedIn = false) => {
    let axiosInstance = isLoggedIn ? instanceAuth() : instance();
    return axiosInstance.get('/v1/web/items/new/', { params });
}
/**
 * 배송섹션 정보 가져오기
 * @returns response
 */
export const getHomeSections = () => {
    return instance().get('/v1/web/sections/');
}

/**
 * 배송섹션 정보 가져오기
 * @returns response
 */
export const getShippingSectionDetail = (id) => {
    return instance().get(`/v1/web/sections/${id}/`);
}