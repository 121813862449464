import { actions } from './actions'
import { mutations } from './mutations'

export const like = {
    state: {
        // pagination
        pagination: {
            page: 1,
            has_next: false,
        },

        selectedTab: 'goods',
        likeItems: [],
        likeTotal: 0,

        likeShops: [],
        likeShopsTotal: 0,

        recentItems: [],
    },
    mutations,
    actions,
}