import { getItemDetail, getItemReviews } from "@/api/item";
import { imageTypeSelector } from "@/plugins/utils";

export const actions = {
    async GET_GOODS_DETAIL({ commit, dispatch, state, rootState }, { id }) {
        commit("SET_LIST_IMAGES", []);
        commit("SET_DETAIL_IMAGES", []);
        commit("RESET_PRODUCT");
        commit("SET_IS_CHARGE_COMBINED", false);
        try {
            let result = await getItemDetail(
                id,
                rootState.user.access_token ? true : false
            );
            if (result.data.code == "S0000" && result.data.data != undefined) {
                commit("SET_PRODUCT", result.data.data);
                commit(
                    "SET_IS_SHIPPING",
                    result.data.data.delivery_type == "S" ? true : false
                );
                commit(
                    "SET_LIST_IMAGES",
                    imageTypeSelector("LIST", result.data.data.item_image)
                );
                commit(
                    "SET_DETAIL_IMAGES",
                    imageTypeSelector("DETAIL", result.data.data.item_image)
                );

                if (state.shipping) {
                    commit("SET_GOODS_REVIEWS", []);
                    commit(
                        "SET_IS_CHARGE_COMBINED",
                        (state.product.shipping_template.is_shipping_group ==
                            true && state.product.shipping_template.charge) > 0
                            ? true
                            : false
                    );
                    dispatch("GET_GOODS_REVIEWS", {
                        id: state.product.id,
                        page: 1,
                    });
                    if (state.isChargeCombined) {
                        const response = await dispatch("GET_GOODS_COMBINED", {
                            page: 1,
                        });
                        if (
                            response.data.code == "S0000" &&
                            response.data.data != undefined
                        ) {
                            commit("SET_COMBINED_SHIPPING", response.data.data);
                        }
                    }
                    commit("SET_THE_OTHERS", []);
                    const response = await dispatch(
                        "FETCH_SHOP_ITEMS_EXCLUDE",
                        {
                            id: state.product.shop.id,
                            exclude: state.product.id,
                        }
                    );
                    if (
                        response.data.code == "S0000" &&
                        response.data.data != undefined
                    ) {
                        commit("SET_THE_OTHERS", response.data.data);
                    }
                }
            }
        } catch (error) {
            console.error("GET_GOODS_DETAIL", error);
            throw error;
        }
    },
    async GET_GOODS_REVIEWS({ commit, state }, { id, page }) {
        let result;
        try {
            result = await getItemReviews(id, {
                is_exist_image: state.isPhotoReview ? true : undefined,
                page: page,
            });
            if (result.data.code == "S0000" && result.data.data != undefined) {
                commit("APPEND_GOODS_REVIEWS", result.data.data);
                commit("SET_REVIEW_TOTAL", result.data.count);
                commit("SET_GOODS_REVIEW_PAGINATION", {
                    page: result.data.page,
                    has_next: result.data.has_next,
                });
            } else if (
                result.data.code == "S0000" &&
                result.data.data == undefined
            ) {
                commit("SET_REVIEW_TOTAL", result.data.count);
                commit("SET_GOODS_REVIEW_PAGINATION", {
                    page: result.data.page,
                    has_next: result.data.has_next,
                });
            }
        } catch (error) {
            console.error("GET_GOODS_REVIEWS", error);
        }

        return result;
    },
    async GET_GOODS_COMBINED({ commit, dispatch, state, rootState }, { page }) {
        let result;
        try {
            result = await dispatch("FETCH_SHOP_ITEMS_COMBINED", {
                id: state.product.shop.id,
                shipping_template_id: state.product.shipping_template.id,
                exclude: state.product.id,
                page: page,
            });
            if (result.data.code == "S0000" && result.data.data != undefined) {
                commit("APPEND_GOODS_COMBINED", result.data.data);
                commit("SET_GOODS_COMBINED_PAGINATION", {
                    page: result.data.page,
                    has_next: result.data.has_next,
                });
            } else if (
                result.data.code == "S0000" &&
                result.data.data == undefined
            ) {
                commit("SET_GOODS_COMBINED_PAGINATION", {
                    page: result.data.page,
                    has_next: result.data.has_next,
                });
            }
        } catch (error) {
            console.error("GET_GOODS_COMBINED", error);
        }

        return result;
    },
};
