import { instance, instanceAuth } from './index';

/**
 * 상품 상세 가져오기
 * @param {*} itemId item index
 * @param {*} isLoggedIn whether to log in
 * @returns response
 */
export const getItemDetail = (itemId, isLoggedIn = false) => {
    let axiosInstance = isLoggedIn ? instanceAuth() : instance();
    return axiosInstance.get(`/v1/web/item/${itemId}/`);
}

/**
 * 상품 옵션 가져오기
 * @param {*} itemNumber item number(20digits)
 * @returns response
 */
export const getItemOptions = (itemNumber) => {
    return instance().get(`/v1/web/item/${itemNumber}/option/`);
}

/**
 * 상품 리뷰 가져오기
 * @param {*} itemId item index
 * @param {*} params query parameters
 * @returns response
 */
export const getItemReviews = (itemId, params) => {
    return instance().get(`/v1/web/item/${itemId}/reviews/list/`, { params });
}

/**
 * 상품 쿠폰 모두받기
 * @param {*} itemId item index
 * @returns response
 */
export const downloadAllCoupons = (itemId) => {
    return instanceAuth().post(`/v1/web/item/${itemId}/coupons/download/`);
}
