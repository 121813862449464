import store from '@/store/index';
import { routes } from '@/router/index';

export const setAuthInterceptors = (instance) => {
    instance.interceptors.request.use(
        (config) => {
            if (store.getters.getAccessToken != '')
                config.headers.Authorization = `${store.getters.getTokenType} ${store.getters.getAccessToken}`;

            return config;
        },
        (error) => Promise.reject(error),
    );

    instance.interceptors.response.use(
        (response) => response,
        errorHandler
    );
    return instance;
}

export const setInterceptors = (instance) => {
    instance.interceptors.request.use((config) => config, (error) => Promise.reject(error));

    instance.interceptors.response.use((response) => response, errorHandler);

    return instance;
}

const errorHandler = (error) => {
    if(error.response.status == 400 || error.response.status == 403) {
        console.error(error.response.data.code, error.response.data.msg);
        alert(error.response.data.msg);
    }
    else if(error.response.status == 401) {
        console.error(error.response.data.code, error.response.data.msg);
        store.dispatch('LOG_OUT')
    }
    else if(error.response.status == 404){
        console.error('404');
    }
    else if(error.response.status >= 500) {
        alert('서버에러가 발생하였습니다.\n잠시 후에 다시 시도해 주십시오.')
    }

    return Promise.reject(error);
};