export const mutations = {
    SET_HOME_BANNERS(state, data) {
        state.middleBanners = [];
        state.bottomBanners = [];

        data.forEach((banner)=> {
            if(banner.position == 'MARKET_MAIN_1')
                state.middleBanners.push(banner);
            else if(banner.position == 'MARKET_MAIN_2')
                state.bottomBanners.push(banner);
        })

        // sort값으로 sorting 오름차순(ascending)
        state.middleBanners.sort((a, b)=> a.sort - b.sort);
        state.bottomBanners.sort((a, b)=> a.sort - b.sort);
    },
    SET_NOTICES(state, data) {
        state.notice = data;
    },
}