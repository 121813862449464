const dotenv = require('dotenv')
dotenv.config()

module.exports = {
  CONFIG: {
    vueAppEnv: '$VUE_APP_ENVIRONMENT',
    vueAppVersion: '$VUE_APP_APP_VERSION',
    vueAppApiUrl: '$VUE_APP_API_URL',
    vueAppServiceStatusUrl: '$VUE_APP_SERVICE_STATUS_URL',
    vueAppKakaoMapApi: '$VUE_APP_KAKAO_MAP_API'
  },
  value: function(name) {
    if (!(name in this.CONFIG)) {
      console.log(`Configuration: There is no key named "${name}"`)
      return
    }

    const value = this.CONFIG[name]

    if (!value) {
      console.log(`Configuration: Value for "${name}" is not defined`)
      return
    }

    if (value.startsWith('$')) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1)
      const envValue = process.env[envName]
      if (envValue) {
        return envValue
      } else {
        console.log(`Configuration: Environment variable "${envName}" is not defined`)
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value
    }
    },
    getOneKey(name) {
        if (!(name in this.CONFIG)) {
            console.log(`Configuration: There is no key named "${name}"`)
            return
        }

        const val = this.CONFIG[name]

        if (!val) {
            console.log(`Configuration: Value for "${name}" is not defined`)
            return
        }

        if (val.indexOf(',') !== -1) {
            const ra = val.split(',')
            return ra[Math.floor(Math.random() * ra.length)]
        }

        return val
    }
}
