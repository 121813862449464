import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store'

Vue.use(VueRouter);

export const routes = new VueRouter({
    mode: 'history',
    routes:[
        {
            path : '/market',
            name: '라오몰',
            redirect: { name: '라오몰 - 홈' },
            component: () => import(/* webpackPrefetch: true */'@/views/home/HomeLayout.vue'),
            children: [
                {
                    path: 'home',
                    name: '라오몰 - 홈',
                    component: () => import(/* webpackPrefetch: true */'@/views/home/HomeWrapper.vue'),
                },
                {
                    path: 'category',
                    name: '카테고리별 목록',
                    component: () => import(/* webpackPrefetch: true */'@/views/home/category/CategoryWrapper.vue'),
                },
                {
                    path: '/tag/:id',
                    name: '속성별 목록',
                    props: true,
                    component: () => import(/* webpackPrefetch: true */'@/views/home/tag/ListWrapper.vue'),
                },
                {
                    path: 'exhibitions',
                    name: '기획전 목록',
                    component: () => import(/* webpackPrefetch: true */'@/views/home/exhibitions/ExhibitionsPage.vue'),
                },
                {
                    path: 'events',
                    name: '이벤트 목록',
                    component: () => import(/* webpackPrefetch: true */'@/views/home/events/EventsPage.vue'),
                },
                {
                    path: 'bests',
                    name: '베스트',
                    props: true,
                    component: () => import(/* webpackPrefetch: true */'@/views/home/bests/BestWrapper.vue'),
                },
                {
                    path: 'new-arrivals',
                    name: 'NEW',
                    props: true,
                    component: () => import(/* webpackPrefetch: true */'@/views/home/new/NewWrapper.vue'),
                },
            ]
        },

        {
            path : '/food',
            name: '내주변 할인',
            redirect: { name: '내주변 할인 - 홈' },
            component: () => import(/* webpackPrefetch: true */'@/views/home/HomeLayout.vue'),
            children: [
                {
                    path: 'home',
                    name: '내주변 할인 - 홈',
                    component: () => import(/* webpackPrefetch: true */'@/views/home/DiscountLayout.vue'),
                }
            ]
        },
        // 사이드바
        {
            path: '/aside',
            name: '사이드바',
            component: () => import(/* webpackPrefetch: true */'@/views/home/AsidePage.vue'),
        },

        // 마이페이지
        {
            path: '/mypage',
            name: '마이페이지',
            component: () => import(/* webpackPrefetch: true */'@/views/mypage/MyPage.vue'),
        },
        // 찜
        {
            path: '/like',
            name: '찜',
            component: () => import(/* webpackPrefetch: true */'@/views/like/LikePage.vue'),
            meta: { requireAuth: true },
        },
        // 구매목록
        {
            path: '/purchaseHistory',
            name: '구매내역',
            component: () => import(/* webpackPrefetch: true */'@/views/purchase/PurchaseHistory.vue'),
            meta: { requireAuth: true },
        },
        // 리뷰 상세
        {
            path: '/review',
            name: '리뷰상세',
            redirect: '/review/create',
            component: () => import('@/views/review/ReviewLayout.vue'),
            children: [
                {
                    path: '/review/create',
                    name: '리뷰작성',
                    props: true,
                    component: () => import('@/views/review/ReviewDetailPage.vue'),
                },
                {
                    path: '/review/:id',
                    name: '리뷰수정',
                    props: true,
                    component: () => import('@/views/review/ReviewDetailPage.vue'),
                },
            ]
        },
        // 상점상세
        {
            path: '/shop/:id',
            name: '업체상세',
            props: true,
            component: () => import('@/views/shop/ShopDetailPage.vue'),
        },
        // 일반 상품 상세
        {
            path: '/goods/:id',
            name: '상품상세',
            props : true,
            component: () => import('@/views/goods/GoodsDetailPage.vue'),
        },
        // 구매내역 상세
        {
            path: '/purchase-detail/:id',
            name: '주문상세',
            props : true,
            component: () => import('@/views/purchase/detail/PurchaseDetailPage.vue'),
        },
        // 구매내역 상세 > 배송추적
        {
            path: '/tracking/:id',
            name: '배송추적',
            props: true,
            component: () => import('@/views/purchase/TrackingPage.vue'),
        },
        // 구매내역 상세 > 가게위치 
        {
            path: '/shop-location/:id',
            name: '가게위치',
            props : true,
            component: () => import('@/views/purchase/detail/ShopLocationPage.vue'),
        },
        // 기획전 > 기획전 상세
        {
            path: '/exhibition/:id',
            name: '기획전상세',
            props : true,
            component: () => import('@/views/pages/ExhibitionDetailPage.vue'),
        },
        // 기획전 > 이벤트 상세
        {
            path: '/event/:id',
            name: '이벤트상세',
            props: true,
            component: () => import('@/views/pages/EventDetailPage.vue'),
        },
        // 주문완료
        {
            path : '/payment-result',
            name: '주문완료',
            component: () => import('@/views/order/PaymentResultPage.vue'),
        },
        // 검색
        {
            path: '/search',
            name: '검색',
            props: true,
            component: () => import('@/views/search/SearchPage.vue'),
        },
        // 사용자 및 가게 위치(native)
        {
            path: '/customer-geolocation',
            name: 'customer-geolocation',
            component: () => import('@/views/pages/CustomerGeolocationPage.vue'),
        },
        // 배송지 선택
        {
            path: '/select-address',
            name: '배송지 선택',
            props: true,
            component: () => import('@/views/address/SelectAddressPage.vue'),
        },
        // 배송지 추가 및 수정
        {
            path: '/address',
            name: '배송지 변경',
            redirect: '/address/create',
            component: () => import('@/views/address/AddressLayout.vue'),
            children: [
                {
                    path: '/address/create',
                    name: '배송지 추가',
                    component: () => import('@/views/address/AddressDetailPage.vue'),
                },
                {
                    path: '/address/:id',
                    name: '배송지 수정',
                    props: true,
                    component: () => import('@/views/address/AddressDetailPage.vue'),
                },
            ]
        },
        // 마이페이지
        {
            path: '/myinfo',
            name: '마이페이지 - 내정보',
            component: () => import('@/views/mypage/info/MyInfoPage.vue'),
        },
        // 쿠폰
        {
            path: '/coupons',
            name: '마이페이지 - 쿠폰',
            component: () => import('@/views/mypage/coupon/MyCouponPage.vue'),
        },
        // 포인트
        {
            path: '/points',
            name: '마이페이지 - 포인트',
            component: () => import('@/views/mypage/point/MyPointPage.vue'),
        },
        // 회원탈퇴
        {
            path: '/resign',
            name: '마이페이지 - 회원탈퇴',
            component: () => import('@/views/mypage/info/ResignPage.vue'),
        },
        // 만료예정 포인트
        {
            path: '/expired-points',
            name: '마이페이지 - 포인트 - 만료예정 포인트',
            component: () => import('@/views/mypage/point/ExpiredPointPage.vue'),
        },
        // 리뷰관리
        {
            path: '/reviews',
            name: '리뷰관리',
            component: () => import('@/views/mypage/review/MyReviewPage.vue'),
        },
        // 고객센터 및 문의
        {
            path: '/customer-service',
            name: '고객센터',
            component: () => import('@/views/mypage/cs/CustomerServicePage.vue'),
        },
        // 지구를 구해주세요.
        {
            path: '/save-earth',
            name: '지구를 구해주세요',
            component: () => import('@/views/events/SaveEarth.vue'),
        },
        // 라오와 함께 하는 환경보호
        {
            path: '/save-earth-event',
            name: '환경보호 캠페인',
            component: () => import('@/views/events/SaveEarthEvent.vue'),
        },
        // 친구초대 이벤트
        {
            path: '/invitation-event',
            name: '친구초대 이벤트',
            component: () => import('@/views/events/InvitedFriend.vue'),
        },
        // 홈 배너, 택배메인 배너 자세히 보기 페이지
        {
            path: '/banners',
            name: '배너전체보기',
            component: () => import('@/views/pages/BannersPage.vue'),
        },

        // 장바구니 신규
        {
            path: '/cart',
            name: '장바구니',
            component: () => import('@/views/cart/CartPage.vue'),
        },
        // 주문하기 신규
        {
            path: '/order',
            name: '주문하기',
            component: () => import('@/views/order/OrderPage.vue'),
        },
        // 결제페이지
        {
            path: '/payment-request',
            name: '결제하기',
            component: () => import('@/views/order/PaymentPage.vue'),
        },
        // 로그인 신규
        {
            path: '/login',
            name: '로그인',
            component: () => import('@/views/login/LoginPage.vue'),
        },
        // 회원가입 신규
        {
            path: '/join',
            name: '회원가입',
            redirect: { name: '약관동의' },
            component: () => import('@/views/join/JoinPageLayout.vue'),
            children: [
                {
                    path: 'agreements',
                    name: '약관동의',
                    component: () => import('@/views/join/AgreementsPage.vue'),
                },
                {
                    path: 'id-authentication',
                    name: '휴대폰인증',
                    component: () => import('@/views/join/IdAuthentication.vue'),
                },
                {
                    path: 'user-information',
                    name: '개인정보 입력',
                    component: () => import('@/views/join/UserForm.vue'),
                },
                {
                    path: 'completed',
                    name: '가입완료',
                    component: () => import('@/views/join/JoinCompleted.vue'),
                },
            ]
        },
        // 앱 url redirection
        {
            // 카테고리
            path: '/home/shipping/sub',
            redirect: { path : '/market/category' },
        },
        // 404
        {
            path: '/404',
            name: '404',
            component: () => import('@/views/404/NotFoundPage.vue'),
        },
        {
            path: '*',
            name: 'nonexisting',
            component: () => import('@/views/404/NotFoundPage.vue')
        },
        {
            path: '/',
            redirect: { name : '라오몰' },
        },
    ],
    scrollBehavior: (to, from, savedPosition)=> {
        if (savedPosition) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(savedPosition)
                }, 300)
            })
        } else {
            return { x: 0, y: 0 }
        }
    },
});


// beforeEach 에서 각 store의 history back 발생을 기억 시킴
window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

// chunk error 처리
routes.onError(error => {
    if (error) console.log(error);
    if (error.stack) console.log(error.stack);
    if (/loading chunk .* failed./i.test(error.message)) {
        console.error('got chunk');
        window.location.reload();
    } else {
        if (confirm('새로고침')) {
            setTimeout(function() {
                window.location.reload();
            }, 1000);
        }
    }
})
// before each
routes.beforeEach(async (to, from, next) => {
    store.state.isPopStateDetected = window.popStateDetected
    window.popStateDetected = false;

    // 서비스중단(중단시 false, 평상시 true)
    if (!store.state.serviceAvailable) {
        next('/home');
    }
    else {
        if(to.matched.some(record => record.meta.requireAuth) && !store.state.user.access_token){
            let wannaLogin = await confirm('로그인이 필요한 기능입니다. 로그인 하시겠습니까?');
            if(wannaLogin) {
                next('/login')
            }
            else {
                next(false);
            }
        }
        else {
            next();
        }
    }
})