<template>
    <header class="header-wrapper">
        <!-- TODO: 수정 -->
        <slot name="topbanner" />
        <div class="header-bar">
            <div class="left">
                <router-link
                    v-if="hasLogo"
                    tag="button"
                    :to="{ name: '라오몰' }"
                >
                    <img
                        class="logo-image"
                        src="https://i.lastorder.in/assets/v2/icon/lago-logo-b.png"
                        alt="logo"
                    >
                </router-link>
                <button
                    v-else-if="hasBack"
                    @click="navigateToBack"
                >
                    <img
                        class="header-icon"
                        src="https://i.lastorder.in/assets/v2/icon/arrow-left.svg"
                        alt="arrow-left"
                    >
                </button>
            </div>

            <!-- 특정 화면에서만 지역명 표시 및 위치설정 기능 적용 -->
            <div class="center">
                <h3 v-if="title">
                    {{ title }}
                </h3>
                <div
                    v-else-if="hasLogo"
                    class="top-select-area"
                >
                    <router-link
                        class="tab-button"
                        data-service="market"
                        :class="{ selected : isMarket }"
                        tag="button"
                        :to="{ name: '라오몰' }"
                    >
                        라오몰
                    </router-link>
                    <router-link
                        class="tab-button"
                        data-service="food"
                        :class="{ selected : !isMarket }"
                        tag="button"
                        :to="{ name: '내주변 할인' }"
                    >
                        내주변할인
                    </router-link>
                </div>
            </div>

            <div class="right">
                <router-link
                    v-if="hasSearch"
                    tag="button"
                    :to="{ path : '/search' }"
                >
                    <img
                        class="header-icon"
                        src="https://i.lastorder.in/assets/v2/icon/search.svg"
                        alt="search"
                    >
                </router-link>
                <router-link
                    v-if="hasCart && accessToken"
                    class="cart-button"
                    tag="button"
                    :to="{ name : '장바구니' }"
                >
                    <img
                        class="header-icon"
                        src="https://i.lastorder.in/assets/v2/icon/cart-b.svg"
                        alt="search"
                    >
                    <span
                        v-show="cartCount > 0"
                        class="badge"
                    >
                        {{ cartCount }}
                    </span>
                </router-link>
                <button
                    v-if="hasPurchaseSearch"
                    @click="visibleSearchModal"
                >
                    <img
                        class="header-icon"
                        src="https://i.lastorder.in/assets/v2/icon/search.svg"
                        alt="search"
                    >
                </button>
            </div>
        </div>
        <slot />
    </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        title: String,
        hasCart: Boolean,
        hasLogo: Boolean,
        hasBack: Boolean,
        hasSearch: Boolean,
        hasPurchaseSearch: Boolean,
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            accessToken: 'getAccessToken',
        }),
        cartCount: {
            get() {
                return this.$store.state.cartCount.market;
            }
        },
        isMarket: {
            get() {
                return this.$store.state.isMarket;
            },
            set(value) {
                this.$store.commit('SET_IS_MARKET', value);
            }
        },
    },
    mounted() {
        if(this.accessToken)
            this.$store.dispatch('GET_CART_COUNT');
    },
    methods: {
        navigateToBack() {
            this.$router.go(-1);
        },
        visibleSearchModal() {
            this.$EventBus.$emit('show-search-modal');
        }
    }
}
</script>

<style scoped lang="scss">
.header-wrapper {
    background: #fff;
    z-index: 11;
    flex: 0;
    position: sticky;
    top: 0;
    width: 100%;
    .header-bar {
        background: #fff;
        box-sizing: border-box;
        width: 100%;
        max-width: 750px;
        display: flex;
        margin: 0 auto;
        justify-content: space-around;
        align-items: center;
        transition: .4s;
        padding: 14px 0;
        @media (min-width: 480px) {
            padding: 18px 0;
        }
        .left {
            width: 30%;
            padding-left: 14px;
            display: flex;
            .logo-image {
                width: 98px;
                aspect-ratio: 7.2;
                vertical-align: middle;
                @media (min-width: 480px) {
                    width: 140px;
                }
            }
        }
        .center {
            width: 40%;
            line-height: 26px;
            h3 {
                text-align: center !important;
                width: 100%;
            }
        }
        .right {
            width: 30%;
            height: 26px;
            padding-right: 14px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .cart-button {
                margin: 0 5px 0 10px;
                position: relative;
                .badge {
                    width: 14px;
                    height: 14px;
                    background-color: #ff4040;
                    color: #fff;
                    border-radius: 8px;
                    font-size: 9px;
                    display: inline-block;
                    position: absolute;
                    top: -5px;
                    right: -10px;
                    line-height: 14px;
                }
            }
        }
    }
}

.header-icon {
    width: 21px;
    height: 21px;
    @media (min-width: 480px) {
        width: 24px;
        height: 24px;
    }
}
.top-select-area {
    border-radius: 5px;
    border: solid 2px #dcdcdc;
    background-color: #efefef;
    display: flex;
    height: 22px;
    width: 130px;
    @media (min-width: 480px) {
        height: 30px;
        width: 170px;
    }
    .tab-button {
        width: 50%;
        height: 100%;
        font-size: 11px;
        font-weight: 500;
        color: #bababa;
        background: #efefef;
        @media (min-width: 480px) {
            font-size: 14px;
        }
        &.selected {
            font-size: 11px;
            font-weight: 500;
            text-align: center;
            color: #353535;
            background: #fff;
            @media (min-width: 480px) {
                font-size: 14px;
            }
        }
    }
}
</style>