import { instanceAuth } from './index';

/**
 * 내 정보 페이지 데이터 가져오기
 * @returns response
 */
export const getMypageInfo = () => {
    return instanceAuth().get('/v1/web/home/');
}

/**
 * 내 정보 가져오기
 * @returns response
 */
export const getProfile = () => {
    return instanceAuth().get('/v1/web/profile/');
}

/**
 * 로그아웃(토큰 삭제)
 * @returns response
 */
export const deleteToken = () => {
    return instanceAuth().post('/v1/web/logout/');
}

/**
 * 내 정보 수정
 * @param {*} json json object
 * @returns response
 */
export const updateProfile = (json) => {
    return instanceAuth().put('/v1/web/profile/', json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 약관 동의 및 해제(마케팅 동의)
 * @param {*} json json object
 * @returns response
 */
export const updateMarketingAgreement = (json) => {
    return instanceAuth().put('/v1/web/profile/agreement/', json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 휴대폰 변경 인증번호 요청
 * @param {*} json json object
 * @returns response
 */
export const requestPhoneCertification = (json) => {
    return instanceAuth().post('/v1/web/profile/mobile/', json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 휴대폰 변경 인증번호 응답
 * @param {*} json json object
 * @returns response
 */
export const verifyPhoneCertification = (json) => {
    return instanceAuth().post('/v1/web/profile/mobile/verify/', json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 현재 포인트 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getPoints = (params) => {
    return instanceAuth().get('/v1/web/point/', { params });
}

/**
 * 소멸 예정 포인트 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getPointExtinctionDetail = (params) => {
    return instanceAuth().get('/v1/web/point/detail/', { params });
}

/**
 * 쿠폰 리스트 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getCoupons = (params) => {
    return instanceAuth().get('/v1/web/coupon/', { params });
}

/**
 * 회원탈퇴
 * @param {*} data user data
 * @returns response
 */
export const resignMember = (data) => {
    return instanceAuth().delete('/v1/web/resign/', {
        headers : { 'Content-Type': 'application/json' },
        data
    });
}

/**
 * 회원 배송 주소지 목록
 * @returns response
 */
export const getAddresses = () => {
    return instanceAuth().get('/v1/web/address/');
}

/**
 * 회원 배송 주소지 추가
 * @param {*} json json data
 * @returns response
 */
export const AddAddress = (json) => {
    return instanceAuth().post('/v1/web/address/', json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 회원 배송 주소지 수정
 * @param {*} id address id
 * @param {*} json json data
 * @returns response
 */
export const updateAddress = (id, json) => {
    return instanceAuth().put(`/v1/web/address/${id}/`, json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 회원 배송 주소지 삭제
 * @param {*} id address id
 * @returns response
 */
export const deleteAddress = (id) => {
    return instanceAuth().delete(`/v1/web/address/${id}/`);
}
