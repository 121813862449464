export default {
    bind(el, binding) {
        try {
            // loader 이미지 추후
            const { value } = binding;
            const fallBackImage = 'https://i.lastorder.in/assets/v2/icon/no-img.svg';
            const img = new Image();
            let error = fallBackImage;
            let original = el.src;
            if (typeof value === 'string') {
                error = value;
            }
            if (value instanceof Object) {
                error = value.fallBackImage || fallBackImage;
            }
            img.src = original;
            img.onload = () => {
                el.src = original;
            };
            img.onerror = () => {
                el.src = error;
            };
        } catch (e) {
            console.error(e)
        }
    }
}