import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const purchase = {
    state: {
        // layout pagination
        pagination: {
            page: 1,
            has_next: false,
        },

        shopLocation: {},

        selectedTab: 'total',
        purchasedList: [],

        isFilteredResult: false,
        purchaseHistoryFilters: {
            searchKeyword: '',
            fromDate: '',
            toDate: '',
        },
        selectedDateFilterIndex: 1,

        purchasedOrder: {},
    },
    getters,
    mutations,
    actions,
}