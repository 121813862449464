
import { shipping } from './shipping/index'
import { like } from './like/index'
import { exhibition } from './exhibition/index'
import { purchase } from './purchase/index'
import { search } from './search/index'
import { shop } from './shop/index'
import { home } from './home/index'
import { goods } from './goods/index'
import { cart } from './cart/index'
import { order } from './order/index'
import { coupon } from './coupon/index'

export const modules = {
    cart,
    shipping,
    like,
    exhibition,
    purchase,
    search,
    shop,
    home,
    goods,
    order,
    coupon,
}