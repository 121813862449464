
import { requestPayment } from '@/api/order'

export const actions = {
    SET_DEFAULT_SHIPPING_INFO({ commit, rootState }) {
        // 기본주소지 설정 안되어 있을 경우
        if(!rootState.cart.orderForm.shippings)
            return;

        commit('SET_SHIPPING_INFO', {
            id: rootState.cart.orderForm.shippings.id,
            description: rootState.cart.orderForm.shippings.comment
        });
    },
    async PAYMENT_REQUEST({ state }) {
        let { data } = await requestPayment(state.paymentData);
        return data
    },
}