import { getCategories } from '@/api/common'
import { getCartCount } from '@/api/cart'
import { getProfile, deleteToken } from '@/api/mypage'

export const actions = {
    async GET_CATEGORIES({ commit }, { type }) {
        try {
            let result = await getCategories({ type });
            if(result.data.code == 'S0000') {
                if(type == 'S'){
                    commit('SET_SHIPPING_SUB_MENUS', result.data.data);
                    commit('SET_SHIPPING_CATEGORIES', result.data.data);
                }
            }
        }
        catch (error) {
            console.error('FETCH_CATOGORIES', error)
        }
    },
    ADD_RECENT_SEARCHWORD({ state, commit, dispatch }, { keyword }) {
        // 최근검색어 중에 중복되는 리스트 찾은 후에 없으면 추가
        // 있으면 array 제일 첫번째 항목에 최근검색어 새로 생성
        const foundIndex = state.recentSearchWords.findIndex((item) => item == keyword);
        if(foundIndex < 0) {
            commit('ADD_RECENT_SEARCHWORD', keyword)
        }
        else {
            dispatch('REMOVE_RECENT_SEARCHWORD', { keyword });
            commit('ADD_RECENT_SEARCHWORD', keyword);
        }
    },
    REMOVE_RECENT_SEARCHWORD({ state, commit }, { keyword }) {
        // 기존에 있는 INDEX 삭제
        const foundIndex = state.recentSearchWords.findIndex((item) => item == keyword);
        if(foundIndex > -1) {
            commit('REMOVE_RECENT_SEARCHWORD', foundIndex)
        }
    },
    REMOVE_ALL_RECENT_SEARCHWORD({ commit }) {
        commit('REMOVE_ALL_RECENT_SEARCHWORD')
    },

    ADD_RECENT_SEARCHLOCATION({ state, commit, dispatch }, location) {
        // 최근주소 중에 중복되는 리스트 찾은 후에 없으면 추가
        // 있으면 array 제일 첫번째 항목에 최근검색어 새로 생성
        const foundIndex = state.recentSearchLocations.findIndex((item) => item.address_name == location.address_name);
        if(foundIndex < 0) {
            commit('ADD_RECENT_SEARCHLOCATION', location)
        }
        else {
            dispatch('REMOVE_RECENT_SEARCHLOCATION', location);
            commit('ADD_RECENT_SEARCHLOCATION', location);
        }
    },
    REMOVE_RECENT_SEARCHLOCATION({ state, commit }, location) {
        // 기존에 있는 INDEX 삭제
        const foundIndex = state.recentSearchLocations.findIndex((item) => item.address_name == location.address_name);
        if(foundIndex > -1) {
            commit('REMOVE_RECENT_SEARCHLOCATION', foundIndex)
        }
    },

    // 로그인 시도 이후 로그인 정보 set
    SET_USER_INFO({ commit }, userData){
        commit('SET_USER_INFO', userData);
    },

    // 위, 경도 설정
    SET_GEOLOCATION({ commit }, userData){
        commit('SET_GEOLOCATION', userData);
    },

    async GET_CART_COUNT({ commit }){
        try {
            const { data } = await getCartCount();
            if(data.data != undefined) {
                data.data.forEach(obj => {
                    if(obj.delivery_type == 'S') {
                        commit('SET_CART_COUNT', {
                            market: obj.item_count
                        });
                    }
                    else if(obj.delivery_type == 'N') {
                        commit('SET_CART_COUNT', {
                            normal: obj.item_count
                        });
                    }
                })
            }
        }
        catch (error) {
            console.error('getCartCountRequest', error);
            commit('SET_CART_COUNT', {
                normal: 0,
                market: 0
            });
        }
    },

    async GET_HAS_ADULT_AUTHENTICATION({ commit }) {
        try {
            const { data } = await getProfile();
            if(data.code == 'S0000') {
                commit('UPDATE_MEMBER_INFO', {
                    id: data.data.id,
                    mobile: data.data.mobile,
                    name: data.data.name,
                    is_adult_verification: data.data.is_adult_verification
                });
            }
        } catch (error) {
            console.error('getProfileAsync', error);
        }
    },

    // logout
    async LOG_OUT({ commit }) {
        try {
            let result = await deleteToken();
            if(result.data.code == 'S0000') {
                commit('LOG_OUT');
                return true;
            }
        }
        catch (error) {
            console.error('LOG_OUT', error)
        }

        return false;
    },

    // 필터 설정(카테고리)
    SET_CATEGORY_FILTER({ commit }, filter) {
        commit('SET_CATEGORY_FILTER', filter);
    },
    // 필터 초기화(카테고리)
    RESET_CATEGORY_FILTER({ commit }) {
        commit('RESET_CATEGORY_FILTER');
        commit('SET_ROOT_FILTER', { status : false })
    },
    // 필터 설정(배송)
    SET_SHIPPING_FILTER({ commit }, filter) {
        commit('SET_SHIPPING_FILTER', filter);
    },
    // 필터 초기화(배송)
    RESET_SHIPPING_FILTER({ commit }) {
        commit('RESET_SHIPPING_FILTER');
    },
}