const url = require('url');
const path = require('path');

/**
 * 상품가격 등 세자리 끊어서 변환
 * @param {*} value price
 * @returns String
 */
export const threeComma = (value) => {
    if(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else {
        return '0'
    }
}

/**
 * 거리표현 km단위 소숫점 한자리 변환
 * @param {Number} value distance
 * @returns String
 */
export const distanceParse = (value) => {
    if(!value)
        return '0 m'

    return value > 999 ? (value / 1000).toFixed(1) + ' km' : value.toFixed(0) + ' m';
}

export const calculateCouponDiscountRate = (originalPrice, couponDiscountedPrice) => {
    // ceil( round(max_discount_price / item.original_price)/100 ,2 ))
    let couponDiscountRate = (originalPrice - couponDiscountedPrice) / originalPrice * 100;
    // 소숫점 둘째자리 반올림
    couponDiscountRate = Math.round(couponDiscountRate * 100) / 100;
    // 소숫점 자리 올림
    couponDiscountRate = Math.ceil(couponDiscountRate);

    return couponDiscountRate;
}

export const calculateCouponDiscountedPrice = (price, couponDiscountRate, rewardRate) => {
    let couponDiscountPrice = price * couponDiscountRate / 100;
    // 소숫점 둘째자리 반올림
    couponDiscountPrice = Math.round(couponDiscountPrice * 100) / 100;
    // 소숫점 자리 올림
    couponDiscountPrice = Math.ceil(couponDiscountPrice);

    let rewardDiscountPrice =  Math.ceil(price * rewardRate / 100);

    return price - couponDiscountPrice - rewardDiscountPrice;
}


/**
 * 타이머
 * @param {*} ms
 * @returns
 */
export const wait = async (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

/**
 * 텍스트 복사
 * @param {input} elementId elemnt id
 */
export const textCopy = (elementId) => {
    let textToCopy = document.getElementById(elementId);
    textToCopy.select()
    textToCopy.setSelectionRange(0, 9999)
    document.execCommand("copy")
}

/**
 * 텍스트 복사
 * @param {String} text text for copy
 */
export const textCopyWithoutInput = (text) => {
    let textToCopy = document.createElement('textarea');

    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(textToCopy);

    // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    textToCopy.value = text;
    textToCopy.select();
    document.execCommand("copy");
    document.body.removeChild(textToCopy);
}

/**
 * select box 넓이 계산
 * @param {id, array, num} select box id값, option list, text size for filter
 * @returns
 */
export const changeElementWidth = (id, array, num) => {
    const filter = document.getElementById(id);
    let textsize = num;
    if (filter && filter.selectedIndex > -1) {
        filter.style.width = array[filter.selectedIndex].label.replace(/[/]/gi, '').length * textsize + 'px';
    }
}

/**
 * Is Null or White space check
 * @param {String} input string value
 * @returns
 */
export const isNullOrWhitespace = (input) => {
    return !input || !input.trim();
}

/**
 * 리뷰 시간 계산
 * @param {Date} date 리뷰 작성 시간
 * @returns 시간
 */
export const timeSince = (date) => {
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;
    if (interval >= 1) {
        return Math.floor(interval) + " 년전";
    }
    interval = seconds / 2592000;
    if (interval >= 1) {
        return Math.floor(interval) + " 달전";
    }
    interval = seconds / 86400;
    if (interval >= 1) {
        return Math.floor(interval) + " 일전";
    }
    interval = seconds / 3600;
    if (interval >= 1) {
        return Math.floor(interval) + " 시간전";
    }
    interval = seconds / 60;
    if (interval >= 1) {
        return Math.floor(interval) + " 분전";
    }
    return Math.floor(seconds) + " 초전";
}

/**
 * 현재기준 해당기간이 지났는지 계산
 * @param {Date} date 비교일
 * @returns days from now
 */
export const daysFromNow = (date) => {
    let seconds = Math.floor((new Date() - date) / 1000);
    // 일단위 변환
    if(seconds < 0) {
        return 0
    }
    else {
        return seconds / 86400;
    }
}

/**
 * 입력된 date를 'yyyy-MM-dd' 형으로 반환
 * @param {Date} date 변환할 날짜
 * @returns {String} yyyy-MM-dd 형태의 string
 */
export const getFormattedDate = (date) => {
    if(date instanceof Date){
        const year = date.getFullYear();
        const month = ("0" + (1 + date.getMonth())).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);

        return year + "-" + month + "-" + day;
    }
    else {
        return '';
    }
}

export const pickupTypeParse = (pickupArray) => {
    if(pickupArray == undefined)
        return ''

    // array 인 케이스
    const pickupObject = {
        E : '매장',
        P : '포장',
        D : '배달',
        S : '택배배송',
    }
    let pickupString = [];

    for (const [key, value] of Object.entries(pickupObject)) {
        if(Array.isArray(pickupArray)) {
            pickupArray.forEach((pickup) => {
                if(pickup == key) {
                    pickupString.push(value)
                }
            });
        }
        else if(typeof pickupArray == 'string') {
            if(pickupArray.includes(key)) {
                pickupString.push(value)
            }
        }
    }

    pickupString.sort();

    return pickupString.join(', ');
}

/**
 * 이미지 썸네일 반환(type 이 List인 경우만 반환)
 * @param {*} typeToSelect image type
 * @param {*} thumbnails 이미지 목록
 * @returns Array
 */
export const imageTypeSelector = (typeToSelect, thumbnails) => {
    if(thumbnails == undefined)
        return [];

    let imageTypeSelectorArray = [];
    if(Array.isArray(thumbnails)) {
        thumbnails.forEach(thumb => {
            if(thumb.type == typeToSelect) {
                imageTypeSelectorArray.push(thumb);
            }
        })
        return imageTypeSelectorArray;
    }
}

/**
 * 썸네일 규칙(ex : 1234.png)
 * 썸네일 128(실제 384px ) : 128.1234.png
 * 썸네일 375(실제 1125px  : )375.1234.png
 * @param {String} imageUrl image url
 * @param {Number} size size want to be
 * @returns image url
 */
export const thumbnailSelecter = (imageUrl, size) => {
    try {
        if(!imageUrl)
            return 'https://i.lastorder.in/assets/v2/icon/no-img.svg'

        let imageUrlparsed = url.parse(imageUrl)
        let urlbasename = path.basename(imageUrlparsed.path);

        if(size == 128){
            urlbasename = '128.' + urlbasename;
            imageUrlparsed.pathname = path.dirname(imageUrlparsed.pathname) + '/' + urlbasename;
            return url.format({
                protocol: imageUrlparsed.protocol,
                hostname: imageUrlparsed.hostname,
                pathname: imageUrlparsed.pathname,
            })
        }
        else if(size == 375){
            urlbasename = '375.' + urlbasename;
            imageUrlparsed.pathname = path.dirname(imageUrlparsed.pathname) + '/' + urlbasename;
            return url.format({
                protocol: imageUrlparsed.protocol,
                hostname: imageUrlparsed.hostname,
                pathname: imageUrlparsed.pathname,
            })
        }
    } catch (error) {
        console.error('thumbnailSelecter', error)
        return 'https://i.lastorder.in/assets/v2/icon/no-img.svg'
    }
}

/**
 * date formatter
 * @param {Date} date date
 * @param {*} format format want to be
 * @return format want to be
 */
export const dateFormatter = (date, format)=> {
    let weekName = ["일", "월", "화", "수", "목", "금", "토"];
    let d = new Date(date);
    let h = d.getHours();

    if(isNaN(d))
        return '';

    return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function($1) {
        switch ($1) {
            case "yyyy": return d.getFullYear();
            case "yy": return (d.getFullYear() % 1000).zf(2);
            case "MM": return (d.getMonth() + 1);
            case "dd": return d.getDate();
            case "E": return weekName[d.getDay()];
            case "HH": return d.getHours().zf(2);
            case "hh": return ((h % 12) ? h : 12).zf(2);
            case "mm": return d.getMinutes().zf(2);
            case "ss": return twoDigit(d.getSeconds());
            case "a/p": return d.getHours() < 12 ? "오전" : "오후";
            default: return $1;
        }
    });
};

// dateFormatter helper
const twoDigit = (value)=> {
    let stringValue = value.toString();
    return stringValue[1] ? stringValue : '0' + stringValue[0]
};
// dateFormatter helper
String.prototype.string = function(len){let s = '', i = 0; while (i++ < len) { s += this; } return s;};
String.prototype.zf = function(len){return "0".string(len - this.length) + this;};
Number.prototype.zf = function(len){return this.toString().zf(len);};

export const setCookie = (name, value, days) => {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + days);
    document.cookie = name + "=" + escape( value ) + "; path=/; expires=" + todayDate.toGMTString() + ";"
};

/**
 * UUID v4 생성
 * @returns uuid
 */
export const uuidV4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

/**
 * 전화번호 -생성
 * @param {*} phoneNumber
 * @returns
 */
export const phoneHyphenHelper = (phoneNumber) => {
    if(isNaN(phoneNumber))
        return phoneNumber;

    return phoneNumber.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3")
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export const getCurrentPosition = async () => {
    let position;
    try {
        position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject)
        })
    } catch (error) {
        console.error('getCurrentPosition', error);
        return null;
    }
    // console.log('getCurrentPosition', position.coords.latitude, position.coords.longitude);

    return {
        lat: position.coords.latitude,
        lng: position.coords.longitude
    }
}