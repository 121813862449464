import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const coupon = {
    state: {
        cartPagination: {
            page: 0,
            has_next: false,
        },
        orderPagination: {
            page: 0,
            has_next: false,
        },
        cartCoupons: [],
        orderCoupons: [],
        cartCouponTotal: 0,
        orderCouponTotal: 0,
    },
    getters,
    mutations,
    actions,
}