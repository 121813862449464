export const mutations = {
    SET_SHOP(state, data) {
        Object.assign(state.shop, data);
    },
    RESET_SHOP(state) {
        state.shop = {
            favorite: undefined,
            franchisee: {},
            holiday: [],
            is_already_download: false,
        };
        state.shopImages = [];
        state.placeOriginImages = [];
        state.commonItems = [];
        state.discountItem = [];
        state.shopTags = [];
        state.reviewTotal = 0;
        state.total = {
            discount: 0,
            common: 0,
        };
    },

    SET_IS_SHIPPING(state, data) {
        state.isShipping = data;
    },

    SET_REVIEWS(state, data) {
        state.reviews = data;
    },
    APPEND_REVIEWS(state, data) {
        data.forEach(item => {
            state.reviews.push(item);
        });
    },
    SET_REVIEW_TOTAL(state, data) {
        state.reviewTotal = data;
    },

    SET_DISCOUNT_ITEMS(state, data) {
        state.discountItem = data;
    },
    APPEND_DISCOUNT_ITEMS(state, data) {
        data.forEach(item => {
            state.discountItem.push(item);
        });
    },
    SET_COMMON_ITEMS(state, data) {
        state.commonItems = data;
    },
    APPEND_COMMON_ITEMS(state, data) {
        data.forEach(item => {
            state.commonItems.push(item);
        });
    },
    SET_TOTAL(state, value) {
        Object.assign(state.total, value);
    },

    SET_CATEGORY_SUMMARIES(state, data) {
        state.categorySummaries = data;
    },
    APPEND_CATEGORY_SUMMARIES(state, data) {
        data.forEach(item => {
            state.categorySummaries.push(item);
        });
    },

    SET_SHOP_TAGS(state, data) {
        state.shopTags = data;
    },

    SET_IS_CVS(state, data) {
        state.isCvs = data;
    },
    SET_IS_PHOTO_REVIEW(state, data) {
        state.isPhotoReview = data;
    },
    SET_STATUS(state, data) {
        state.status = data;
    },
    SET_SELECTED_TAB(state, data) {
        state.selectedTab = data;
    },
    SET_ACTIVE_CATEGORY(state, data) {
        state.activeCategory = data;
    },
    SET_SELECTED_TAG(state, data) {
        state.selectedTag = data;
    },
    SET_SEARCH_RESULT(state, data) {
        state.searchResult = data;
    },
    SET_SEARCH_KEYWORD(state, data) {
        state.searchKeyword = data;
    },

    SET_PAGINATION(state, value) {
        Object.assign(state.pagination, value);
    },
    SET_COMMONITEM_PAGINATION(state, value) {
        Object.assign(state.commonitemPagination, value);
    },
    SET_REVIEW_PAGINATION(state, value) {
        Object.assign(state.reviewPagination, value);
    },

    SET_SHOP_IMAGES(state, data) {
        state.shopImages = data;
    },
    SET_SHOP_PLACE_ORIGIN_IMAGES(state, data) {
        state.placeOriginImages = data;
    },
    SET_SHOP_DELIVERY_INFO(state, data) {
        state.shopDeliveryInfomation = data;
    },
}