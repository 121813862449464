import { instanceAuth } from './index';

/**
 * 일반/택배주문 구매내역 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getOrderHistoryList = (params) => {
    return instanceAuth().get('/v2/web/order/history/', { params });
}

/**
 * 주문서 내 주문서쿠폰 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getOrderCoupons = (params) => {
    return instanceAuth().get('/v1/web/coupon/order/', { params });
}

/**
 * 일반/택배주문 구매내역 상세
 * @param {*} orderNumber order number
 * @returns response
 */
export const getOrderHistoryDetail = (orderNumber) => {
    return instanceAuth().get(`/v2/web/order/history/${orderNumber}/`);
}

/**
 * 주문 배송지 수정
 * @param {*} orderShippingId shipping id
 * @param {*} json json data
 * @returns response
 */
export const updateOrderShippingAddress = (orderShippingId, json) => {
    return instanceAuth().put(`/v1/web/order/shipping/address/${orderShippingId}/`, json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 구매내역 매장위치
 * @param {*} shopId shop index
 * @returns response
 */
export const getShopLocation = (shopId) => {
    return instanceAuth().get(`/v1/web/shop/${shopId}/location/`);
}

/**
 * 주문취소 요청
 * @param {*} json json object
 * @returns response
 */
export const orderCancel = (json) => {
    return instanceAuth().post('/v2/purchase/cancel/', json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 주문확정 요청
 * @param {*} shippingId shipping index
 * @returns response
 */
export const orderConfirm = (shippingId) => {
    return instanceAuth().put(`/v1/web/order/${shippingId}/confirm/`);
}

/**
 * 배송추적
 * @param {*} shippingId shipping index
 * @returns response
 */
export const getShippingTracking = (shippingId) => {
    return instanceAuth().get(`/v1/web/order/history/${shippingId}/tracking/`);
}

/**
 * PG 요청
 * @param {*} json json object
 * @returns response
 */
export const requestPayment = (json) => {
    return instanceAuth().post('/v2/purchase/payment/', json, {
        headers : { 'Content-Type': 'application/json' },
    });
}
