import { getExhibitions, getHomeExhibitions, getExhibitionDetail } from '@/api/event'

export const actions = {
    async FETCH_EXHIBITIONS({ commit }, { page }) {
        let result = undefined;

        try {
            result = await getExhibitions({
                delivery_type: 'S',
                page,
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_EXHIBITIONS', result.data.data);
                commit('SET_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_EXHIBITIONS', error);
        }

        return result;
    },
    async FETCH_MAIN_EXHIBITIONS({ commit }, { page }) {
        let result = undefined;

        try {
            result = await getHomeExhibitions({
                delivery_type: 'S',
                page
            });
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_EXHIBITIONS', result.data.data);
                commit('SET_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_MAIN_EXHIBITIONS', error);
        }

        return result;
    },
    async FETCH_EXHIBITION_DETAIL({ commit, rootState }, { id, sort_type, distance, page }) {
        let result;
        try {
            result = await getExhibitionDetail(id, {
                lat: rootState.geolocation.latitude,
                lng: rootState.geolocation.longitude,
                sort_type,
                distance,
                page,
            });
            if(result.data.code == 'S0000') {
                commit('SET_TITLE', result.data.exhibition_title.title);
                commit('SET_IMAGE', result.data.exhibition_title.image);
                commit('SET_ROW_COUNT', result.data.exhibition_title.count_in_mobile_row);
                commit('SET_DETAIL_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
                if(result.data.data != undefined) {
                    commit('APPEND_EXHIBITION_DETAIL', result.data.data);
                }
            }
        }
        catch (error) {
            console.error('FETCH_MAIN_EXHIBITIONS', error);
        }
        return result;
    },
}