import axios from 'axios';
import { setInterceptors, setAuthInterceptors } from './common/interceptors';
const Configuration = require('../configuration');
const vueAppApiUrl = Configuration.value('vueAppApiUrl');


export const instance = ()=> {
	const axiosInstance = axios.create({
		baseURL: vueAppApiUrl,
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
	return setInterceptors(axiosInstance);
}

// 액시오스 초기화 함수
export const instanceAuth = ()=> {
	const axiosInstance = axios.create({
		baseURL: vueAppApiUrl,
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
	return setAuthInterceptors(axiosInstance);
}
