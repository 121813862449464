export const mutations = {
    ADD_RECENT_SEARCHWORD(state, keyword) {
        state.recentSearchWords.splice(0, 0, keyword)
    },
    REMOVE_RECENT_SEARCHWORD(state, index) {
        state.recentSearchWords.splice(index, 1);
    },
    REMOVE_ALL_RECENT_SEARCHWORD(state) {
        state.recentSearchWords = [];
    },

    ADD_RECENT_SEARCHLOCATION(state, location) {
        state.recentSearchLocations.splice(0, 0, location)
    },
    REMOVE_RECENT_SEARCHLOCATION(state, index) {
        state.recentSearchLocations.splice(index, 1);
    },

    SET_ROOT_FILTER(state, value) {
        Object.assign(state.filter, value);
    },
    SET_APP_BANNER_VISIBLE(state, value) {
        state.showAppBanner = value;
    },

    SET_USER_INFO(state, userData) {
        Object.assign(state.user, userData);
    },
    UPDATE_MEMBER_INFO(state, userData) {
        Object.assign(state.user.member, userData);
    },
    SET_GEOLOCATION(state, userData){
        state.geolocation = userData;
    },
    SET_CART_COUNT(state, userData){
        Object.assign(state.cartCount, userData);
    },
    SET_UUID(state, value){
        state.uuid = value;
    },
    SET_IS_MARKET(state, value) {
        state.isMarket = value;
    },

    SET_SHIPPING_SUB_MENUS(state, data) {
        // sorting으로 값 정렬 변경
        data.sort((a, b)=> a.sort - b.sort);

        data.forEach(category => {
            if(state.shippingSubMenus.every(menu => menu.name != category.name)) {
                state.shippingSubMenus.push({
                    name: category.name,
                    value: category.id,
                    path: `/market/category?category=${category.id}`,
                    order: state.shippingSubMenus.length,
                    fixed: false,
                });
            }
        });
    },
    SET_SHIPPING_CATEGORIES(state, data) {
        state.shippingCategories = data;
    },

    LOG_OUT(state) {
        Object.assign(state.user, {
            token_type: undefined,
            access_token : undefined,
            expires_in : 0,
            scope : undefined,
            member: {
                id : undefined,
                mobile : undefined,
                name : undefined,
                is_adult_verification: false,
            }
        });
    },

    SET_SERVICE_STATUS(state, value) {
        state.serviceAvailable = value;
    },
}