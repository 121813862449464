<template>
    <transition name="fade">
        <div
            v-show="isActive"
            class="toast-msg-area"
            :class="{
                'on-bottom' : position == 'bottom',
                'on-top' : position == 'top',
                'on-mid' : position == 'middle',
            }"
        >
            <div class="msg">
                <div
                    v-for="text in message"
                    :key="text"
                >
                    {{ text }}
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        message: Array,
        position: {
            type: String,
            default: 'bottom',
        },
    },
    data() {
        return {
            isActive: false,
            parentTop: null,
            parentBottom: null,
        }
    },
    beforeMount() {
        this.setupContainer();
    },
    mounted() {
        this.showToast();
    },
    methods: {
        setupContainer() {
            this.parentTop = document.querySelector('.v-toast.v-toast--top');
            this.parentBottom = document.querySelector('.v-toast.v-toast--bottom');
            // No need to create them, they already exists
            if (this.parentTop && this.parentBottom) return;

            if (!this.parentTop) {
                this.parentTop = document.createElement('div');
                this.parentTop.className = 'v-toast v-toast--top';
            }

            if (!this.parentBottom) {
                this.parentBottom = document.createElement('div');
                this.parentBottom.className = 'v-toast v-toast--bottom'
            }

            const container = document.body;
            container.appendChild(this.parentTop);
            container.appendChild(this.parentBottom);
        },
        shouldQueue() {
            if (!this.queue) return false;

            return (
                this.parentTop.childElementCount > 0 ||
                this.parentBottom.childElementCount > 0
            )
        },
        showToast() {
            this.parentBottom.insertAdjacentElement('afterbegin', this.$el);
            this.isActive = true;
            setTimeout(()=> {
                this.isActive = false;
                this.$destroy();
                if (typeof this.$el.remove !== 'undefined') {
                    this.$el.remove()
                } else {
                    this.$el.parentNode.removeChild(this.$el)
                }
            }, 1800);
        }
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.4s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.toast-msg-area {
    z-index: 100000;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    transition: .4s;
    &.on-top {
        top: 40px;
    }
    &.on-mid {
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &.on-bottom {
        bottom: 100px;
    }
    &.on-map {
        bottom: 156px;
        .msg {
            margin: 0 auto;
            padding: 10px 14px;
            max-width: 200px;
            background: #0000008c;
        }
    }
    .msg {
        background: #000000b2;
        padding: 12px 20px;
        color: #fff;
        font-size: 14px;
        border-radius: 30px;
        margin: 0 35px;
        text-align: center;
    }
}
</style>