import Vue from "vue";
import App from "./App.vue";
import Axios from "axios";
import { routes } from "@/router";
import store from "@/store";
import HeaderBar from "@/components/HeaderBar";
import BottomNav from "@/components/BottomNav";
import * as Util from "@/plugins/utils";
import * as mapLoader from "@/plugins/mapLoader";

// custom utility
Vue.prototype.$utils = Util;
Vue.prototype.$mapLoader = mapLoader;

// declare event bus
Vue.prototype.$EventBus = new Vue();

import ToastComponent from "@/components/toast/";
Vue.use(ToastComponent);

// 이미지 fallback directive
import ImageFallBack from "@/plugins/imageErrorDirective";
Vue.directive("image-fall-back", ImageFallBack);

// vue-awesome-swiper import style
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

// vue loading overlay
import Loading from "vue-loading-overlay"; // Import component
import "vue-loading-overlay/dist/vue-loading.css"; // Import stylesheet
Vue.use(
    Loading,
    { color: "gray", height: 30, width: 30, opacity: 0 },
    {
        /* slots */
    }
); // Init plugin

// vue infinite loading
import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading, {
    slots: {
        noMore: "",
        noResults: "",
    },
});

// vue-meta
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true,
});

// 다음포스트코드
import VueDaumPostcode from "vue-daum-postcode";
Vue.use(VueDaumPostcode);

// 다음 지도
import VueDaumMap from "@/components/VueDaumMap";
Vue.component("VueDaumMap", VueDaumMap);

// barcode
import VueBarcode from "vue-barcode";
Vue.component("VueBarcode", VueBarcode);

// custom-header bar
Vue.component("HeaderBar", HeaderBar);
Vue.component("BottomNav", BottomNav);

// image lazy loading
import AppImage from "@/components/AppImage";
Vue.component("AppImage", AppImage);

// dom purify
import VueDOMPurifyHTML from "vue-dompurify-html";
Vue.use(VueDOMPurifyHTML);

// ElasticAPM RUM
// TODO:APM비활성화
// import { ApmVuePlugin } from "@elastic/apm-rum-vue";


const Configuration = require('./configuration')
let stage = Configuration.value('vueAppEnv')
let vueAppVersion = Configuration.value('vueAppVersion')
let serviceCheckURL = Configuration.value('vueAppServiceStatusUrl')

const data = {
    stage: stage,
    serviceCheckURL: serviceCheckURL,
};
// TODO:APM비활성화
// Vue.use(ApmVuePlugin, {
//     router: routes,
//     config: {
//         serviceName: "service-web",
//         serviceVersion: vueAppVersion, // required
//         environment: stage,
//         serverUrl: "/", // uses my own apm server (for security reason, we doesn't exposes our main elasticsearch node)
//     },
// });
new Vue({
    data,
    router: routes,
    store,
    render: (h) => h(App),
}).$mount("#app");

