import { instance } from './index';

/**
 * example code
 * @param {*} params parameters
 * @returns response
 */
export const getExample = (params) => {
	return {
        status : 200,
        data : {
            code : 'S0000',
            data : {
                result : 'example'
            }
        }
    };
}

/**
 * 코드 목록 가져오기
 * @returns response
 */
export const getCodes = () => {
    return instance().get('/v1/salad/codes/');
}

/**
 * 카테고리 목록 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getCategories = (params) => {
    return instance().get('/v1/salad/categories/', { params });
}

/**
 * 태그 정보 가져오기
 * @param {*} id id
 * @returns response
 */
export const getTagInfo = (id) => {
    return instance().get(`/v1/web/tags/${id}/`);
}

/**
 * 공지사항 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getPosts = (params) => {
    return instance().get('/v1/web/post/', { params });
}

/**
 * FAQ 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getFaqs = (params) => {
    return instance().get('/v1/web/faq/', { params });
}

/**
 * board 카테고리 가져오기
 * @param {*} params query parameters
 * @returns response
 */
export const getBoardCategory = (params) => {
    return instance().get('/v1/web/board-category/', { params });
}