import { instanceAuth } from './index';

/**
 * 찜한 상품 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getFavoriteItems = (params) => {
    return instanceAuth().get('/v1/web/hearts/', { params });
}

/**
 * 상품 알림 설정 변경
 * @param {*} likeId like id
 * @param {*} json json object
 * @returns response
 */
export const toggleItemAlarm = (likeId, json) => {
    return instanceAuth().put(`/v1/web/hearts/${likeId}/noti/`, json, {
        headers : { 'Content-Type': 'application/json' },
    });
}

/**
 * 찜한 상품 설정
 * @param {*} itemId item id
 * @returns response
 */
export const setFavoriteItem = (itemId) => {
    return instanceAuth().post(`/v1/web/item/${itemId}/heart/`);
}

/**
 * 찜한 상품 해제
 * @param {*} itemId item id
 * @returns response
 */
export const removeFavoriteItem = (itemId) => {
    return instanceAuth().delete(`/v1/web/hearts/${itemId}/`);
}

/**
 * 자주 찾는 가게 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getFavoriteShops = (params) => {
    return instanceAuth().get('/v1/web/favorites/', { params });
}

/**
 * 자주 찾는 가게 설정
 * @param {*} shopId shop id
 * @returns response
 */
export const setFavoriteShop = (shopId) => {
    return instanceAuth().post(`/v1/web/shop/${shopId}/favorites/`);
}

/**
 * 자주 찾는 가게 해제
 * @param {*} favoriteId favorite id
 * @returns response
 */
export const removeFavoriteShop = (favoriteId) => {
    return instanceAuth().delete(`/v1/web/favorites/${favoriteId}/`);
}

/**
 * 최근 본 상품 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getRecentItems = (params) => {
    return instanceAuth().get('/v1/web/item/recent/', { params });
}

/**
 * 최근 본 상품 삭제
 * @param {*} itemId item id
 * @returns response
 */
export const removeRecentItem = (itemId) => {
    return instanceAuth().delete(`/v1/web/item/${itemId}/recent/`);
}
