import { instance, instanceAuth } from './index';

/**
 * 이벤트 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getEvents = (params) => {
    return instance().get('/v1/web/events/', { params });
}

/**
 * 이벤트 상세
 * @param {*} eventId event index
 * @returns response
 */
export const getEventDetail = (eventId) => {
    return instance().get(`/v1/web/events/${eventId}/`);
}

/**
 * 기획전 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getExhibitions = (params) => {
    return instance().get('/v1/web/exhibitions/', { params });
}

/**
 * 홈화면 기획전 목록
 * @param {*} params query parameters
 * @returns response
 */
export const getHomeExhibitions = (params) => {
    return instance().get('/v1/web/dashboard/exhibitions/', { params });
}

/**
 * 기획전 상세
 * @param {*} exhibitionId exhibition index
 * @param {*} params query parameters
 * @returns response
 */
export const getExhibitionDetail = (exhibitionId, params) => {
    return instance().get(`/v1/web/exhibitions/${exhibitionId}/`, { params });
}

/**
 * 전체 그릇수, 나의 그릇수 api
 * @param {*} isLoggedIn whether to log in
 * @returns response
 */
export const getDishCount = (isLoggedIn)=> {
    let axiosInstance = isLoggedIn ? instanceAuth() : instance();
    return axiosInstance.get('/v1/web/earth/');
}
