import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const search = {
    state: {
        // 검색 이후 화면 여부
        showSearchResult: false,
        searchInput: '',

        // pagination
        shopPagination: {
            page: 1,
            has_next: false,
        },
        menuPagination: {
            page: 1,
            has_next: false,
        },
        itemPagination: {
            page: 1,
            has_next: false,
        },

        selectedTab : 'shops',

        shops : [],
        menus : [],
        items : [],

        shopsTotal: 0,
        menusTotal: 0,
        itemsTotal: 0,

        searchFilter: {
            brand_code: undefined,
            pickup_method: 'P,E,D',
            sort_type: 1,
            shipping_sort_type: 8,
            status: false,
        },
    },
    getters,
    mutations,
    actions,
}