import {
    getMarketCart,
    optimizeMarketCoupon,
    getCartCount,
    requestPurchaseForm,
} from "@/api/cart";
import { getBanners } from '@/api/home'

export const actions = {
    async FETCH_SHOPPING_CART_BANNERS({ commit }) {
        try {
            let result = await getBanners('SHOPPING_CART');
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('SET_SHOPPING_CART_BANNERS', result.data.data);
            }
        }
        catch (error) {
            console.error('FETCH_SHOPPING_CART_BANNERS', error);
        }
    },
    async FETCH_CART_SUMMARY({ commit }) {
        try {
            let { data } = await getCartCount();
            if (data.code == "S0000") {
                if (data.data) {
                    commit("SET_CART_SUMMARY", data.data);
                }
            }
        } catch (error) {
            console.error("FETCH_CART_SUMMARY", error);
        }
    },
    async FETCH_CART({ commit, dispatch }, { selectionRefresh = false }) {
        try {
            let { data } = await getMarketCart();
            if (data.code == "S0000") {
                if (data.data) {
                    if (selectionRefresh)
                        commit("INIT_CHECKED_ARRAY", data.data);

                    commit("SET_CART_SHOPS", data.data);
                } else {
                    commit("SET_CART_SHOPS", []);
                }

                await dispatch("FETCH_CART_SUMMARY");

                return data.data;
            }
        } catch (error) {
            console.error("FETCH_CART", error);
            await dispatch("FETCH_CART", { selectionRefresh });
        }
    },
    async OPTIMIZE_COUPON({ commit }, params) {
        try {
            let { data } = await optimizeMarketCoupon(params);
            if (data.code == "S0000") {
                if (data.data) {
                    commit("SET_COUPON_OPTIMIZED", true);
                    commit("SET_CART_SHOPS", data.data);
                } else {
                    commit("SET_COUPON_OPTIMIZED", true);
                    commit("SET_CART_SHOPS", []);
                }
            }
        } catch (error) {
            console.error("OPTIMIZE_COUPON", error);
        }
        return;
    },
    async SELECT_OPTIMIZE({ commit, state }, params) {
        try {
            let { data } = await optimizeMarketCoupon(params);
            if (data.code == "S0000") {
                if (data.data) {
                    commit("SET_COUPON_OPTIMIZED", true);
                    const result = state.shopCarts.map((shop) => {
                        data.data.forEach((dataShop) => {
                            if (dataShop.shop.id == shop.shop.id) {
                                shop.bundles = shop.bundles.map((item) => {
                                    dataShop.bundles.forEach((dataBundles) => {
                                        dataBundles.items.forEach(
                                            (updateItem) => {
                                                if (
                                                    updateItem.id ===
                                                    item.items.id
                                                ) {
                                                    item.items = updateItem;
                                                }
                                            }
                                        );
                                        if (
                                            item.shipping.id ===
                                            dataBundles.shipping.id
                                        )
                                            item.shipping =
                                                dataBundles.shipping;
                                    });
                                    return item;
                                });
                            }
                        });
                        return shop;
                    });
                    this.commit("SET_CART_SHOPS", result);
                } else {
                    commit("SET_COUPON_OPTIMIZED", true);
                    // commit("SET_CART_SHOPS", []);
                }
            }
        } catch (error) {
            console.error("OPTIMIZE_COUPON", error);
        }
        return;
    },

    async GET_ORDER_FORM({ commit, rootState }, params) {
        let { data } = await requestPurchaseForm(params);
        if (data.code == "S0000") {
            if (!rootState.isPopStateDetected)
                commit("SET_ORDER_FORM", data.data);
        }

        return;
    },
};
