export const getters = {
    getShoppingCart1Banners: state => state.shoppingCart1Banners,
    getTotalCount: (state, getters) => {
        // 각 상품 qty 총합
        let returnValue = 0;
        state.shopCarts.forEach((shop) => {
            shop.bundles.forEach((bundle) => {
                returnValue += bundle.items
                    .map((item) => {
                        return getters.getSelectedItemIds.includes(item.id)
                            ? item.qty
                            : 0;
                        // let isExist = state.checkedShops.some(s => s.bundles.some(b => b.items.some(i => i.id == item.id && i.checked)))
                        // return isExist ? item.qty : 0
                    })
                    .reduce((prev, curr) => prev + curr);
            });
        });
        return returnValue;
    },
    getGoodsTotalPrice: (state, getters) => {
        // 총 상품금액 : 각 선택된 상품 total_price 총합
        let returnValue = 0;
        state.shopCarts.forEach((shop) => {
            shop.bundles.forEach((bundle) => {
                returnValue += bundle.items
                    .map((item) => {
                        return getters.getSelectedItemIds.includes(item.id)
                            ? item.total_price
                            : 0;
                    })
                    .reduce((prev, curr) => prev + curr);
            });
        });
        return returnValue;
    },
    getOrderTotalPrice: (state, getters) => {
        // 총 주문금액 : 각 상품 calculate_price 총합(장바구니 쿠폰 적용가)
        let returnValue = 0;
        state.shopCarts.forEach((shop) => {
            shop.bundles.forEach((bundle) => {
                returnValue += bundle.items
                    .map((item) => {
                        return getters.getSelectedItemIds.includes(item.id)
                            ? item.calculate_price
                            : 0;
                    })
                    .reduce((prev, curr) => prev + curr);
            });
        });
        return returnValue;
    },
    getTotalCouponDiscountPrice: (state, getters) => {
        // 상품쿠폰할인 : 각 상품별 member_coupon 가격 총합(상품 쿠폰할인)
        let returnValue = 0;
        state.shopCarts.forEach((shop) => {
            shop.bundles.forEach((bundle) => {
                returnValue += bundle.items
                    .map((item) => {
                        return getters.getSelectedItemIds.includes(item.id) &&
                            item.member_coupon
                            ? item.member_coupon.coupon.discount_price
                            : 0;
                    })
                    .reduce((prev, curr) => prev + curr);
            });
        });
        return returnValue;
    },
    getRewardPrice: (state, getters) => {
        // 적립혜택 : 각 상품별 reward 가격 총합
        let returnValue = 0;
        state.shopCarts.forEach((shop) => {
            shop.bundles.forEach((bundle) => {
                returnValue += bundle.items
                    .map((item) => {
                        return getters.getSelectedItemIds.includes(item.id)
                            ? item.reward
                            : 0;
                    })
                    .reduce((prev, curr) => prev + curr);
            });
        });
        return returnValue;
    },
    getShippingBasePrice: (state) => {
        // 기본 배송비 합계
        let returnValue = 0;
        state.shopCarts.forEach((shop) => {
            returnValue += shop.bundles
                .map((bundle) => {
                    if (bundle.shipping.charge_type == "EACH") {
                        let sumValue = 0;
                        bundle.items.forEach((item) => {
                            if (
                                state.checkedShops.some((s) =>
                                    s.bundles.some((b) =>
                                        b.items.some(
                                            (i) => i.id == item.id && i.checked
                                        )
                                    )
                                )
                            ) {
                                if (state.orderForm.shippings) {
                                    return (sumValue +=
                                        bundle.shipping
                                            .calculate_shipping_charge);
                                }
                            }
                        });
                        return sumValue;
                    } else {
                        let isExist = bundle.items.some((item) =>
                            state.checkedShops.some((s) =>
                                s.bundles.some((b) =>
                                    b.items.some(
                                        (i) => i.id == item.id && i.checked
                                    )
                                )
                            )
                        );
                        return isExist
                            ? bundle.shipping.calculate_shipping_charge
                            : 0;
                    }
                })
                .reduce((prev, curr) => prev + curr);
        });
        return returnValue;
    },
    getShippingExtraPrice: (state) => {
        // 도서산간 배송비 합계
        let returnValue = 0;
        state.shopCarts.forEach((shop) => {
            returnValue += shop.bundles
                .map((bundle) => {
                    if (bundle.shipping.charge_type == "EACH") {
                        let sumValue = 0;
                        bundle.items.forEach((item) => {
                            if (
                                state.checkedShops.some((s) =>
                                    s.bundles.some((b) =>
                                        b.items.some(
                                            (i) => i.id == item.id && i.checked
                                        )
                                    )
                                )
                            ) {
                                if (state.orderForm.shippings) {
                                    if (
                                        state.orderForm.shippings
                                            .extra_charge_type == 1
                                    ) {
                                        return (sumValue +=
                                            bundle.shipping
                                                .calculate_shipping_jeju_beside_charge);
                                    } else if (
                                        state.orderForm.shippings
                                            .extra_charge_type == 2
                                    ) {
                                        return (sumValue +=
                                            bundle.shipping
                                                .calculate_shipping_jeju_charge);
                                    } else {
                                        return 0;
                                    }
                                }
                            }
                        });
                        return sumValue;
                    } else {
                        let isExist = bundle.items.some((item) =>
                            state.checkedShops.some((s) =>
                                s.bundles.some((b) =>
                                    b.items.some(
                                        (i) => i.id == item.id && i.checked
                                    )
                                )
                            )
                        );
                        if (isExist) {
                            if (state.orderForm.shippings) {
                                if (
                                    state.orderForm.shippings
                                        .extra_charge_type == 1
                                ) {
                                    return bundle.shipping
                                        .calculate_shipping_jeju_beside_charge;
                                } else if (
                                    state.orderForm.shippings
                                        .extra_charge_type == 2
                                ) {
                                    return bundle.shipping
                                        .calculate_shipping_jeju_charge;
                                } else {
                                    return 0;
                                }
                            }
                        }

                        return 0;
                    }
                })
                .reduce((prev, curr) => prev + curr);
        });
        return returnValue;
    },
    getPaymentItemIds: (state) => {
        let items = [];
        state.shopCarts.forEach((shop) => {
            let bundles = [];
            shop.bundles.forEach((bundle) => {
                let bundleItems = [];
                bundle.items.forEach((bundleItem) => {
                    let isExist = state.checkedShops.some((s) =>
                        s.bundles.some((b) =>
                            b.items.some(
                                (i) => i.id == bundleItem.id && i.checked
                            )
                        )
                    );
                    if (isExist) {
                        bundleItems.push({
                            cart: bundleItem.id,
                        });
                    }
                });
                if (bundleItems.length > 0) {
                    bundles.push({
                        shipping: bundle.shipping.id,
                        items: bundleItems,
                    });
                }
            });

            if (bundles.length > 0) {
                items.push({
                    shop: shop.shop.id,
                    bundles,
                });
            }
        });
        return items;
    },
    getSelectedItemIds: (state) => {
        let bundleItemIds = [];
        state.shopCarts.forEach((shop) => {
            shop.bundles.forEach((bundle) => {
                bundle.items.forEach((bundleItem) => {
                    let isExist = state.checkedShops.some((s) =>
                        s.bundles.some((b) =>
                            b.items.some(
                                (i) => i.id == bundleItem.id && i.checked
                            )
                        )
                    );
                    if (isExist) {
                        bundleItemIds.push(bundleItem.id);
                    }
                });
            });
        });
        return bundleItemIds;
    },
    getOrderTotalValue: (state, getters) => {
        return (
            getters.getOrderTotalPrice +
            state.deliveryPriceSummary.delivery_total +
            getters.getShippingBasePrice +
            getters.getShippingExtraPrice -
            state.orderCouponPrice -
            state.pointValue
        );
    },
    getCartSummary: (state) => state.cartSummary,
};
