<template>
    <transition name="slide">
        <router-view />
    </transition>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
    metaInfo: {
        title: '라스트오더',
        meta: [
            {
                vmid: 'og:title',
                property: 'og:title',
                content: '라스트오더',
            },
            {
                vmid: 'og:image',
                property: 'og:image',
                content: 'https://i.lastorder.in/assets/v2/icon/lastorder.jpg',
            },
            {
                vmid: 'og:description',
                property: 'og:description',
                content: '라스트오더에서 나와 가까운 곳의 마감세일을 만나보세요.',
            },
        ]
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            uuid: 'getUUID',
            userId: 'getUserId',
            accessToken: 'getAccessToken',
        })
    },
    created() {
        // 서비스 사용가능 상태 점검
        this.checkServiceAvailable();

        if(!this.uuid){
            this.$store.commit('SET_UUID', this.$utils.uuidV4());
        }

        if(this.accessToken) {
            this.$store.dispatch('GET_HAS_ADULT_AUTHENTICATION');
        }

        // gtag 설정 추가
        gtag('config', 'G-663DFBC3JT', {
            user_id: this.userId ? this.userId.toString() : null,
        });
        gtag('set', 'user_properties', {
            user_id_dimension: this.userId ? this.userId.toString(): null,
        });
    },
    methods: {
        checkServiceAvailable(){
            axios({
                method: 'get',
                url: this.$root.$data.serviceCheckURL,
                headers: {},
            }).then( result => {
                let data = result.data;
                if(data.status == 'unhealthy' || data.status == 'unavailable'){
                    this.$store.commit('SET_SERVICE_STATUS', false);
                }
                else {
                    this.$store.commit('SET_SERVICE_STATUS', true);
                }
            }).catch(err => {
                console.error('CHECK_SERVICE_AVAILABLE', err)
            });
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
