import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const shipping = {
    state: {
        topBanners:[],
        midBanners:[],
        shippingDetail1Banners:[],
        exhibitionImage: '',

        tagTitle: '',
        tagDescription: '',
        pagination: {
            page: 1,
            has_next: false,
        },
        shippingItems: [],
        shippingFilter: {
            sort_type: 8,
            is_coupon: false,
        },
    },
    getters,
    mutations,
    actions,
}