export const mutations = {
    SET_SEARCH_SELECTED_TAB(state, value) {
        state.selectedTab = value;
    },
    SET_SEARCH_SHOP_PAGINATION(state, value) {
        Object.assign(state.shopPagination, value);
    },
    SET_SEARCH_MENU_PAGINATION(state, value) {
        Object.assign(state.menuPagination, value);
    },
    SET_SEARCH_ITEM_PAGINATION(state, value) {
        Object.assign(state.itemPagination, value);
    },

    SET_SHOPS(state, data) {
        state.shops = data;
    },
    APPEND_SHOPS(state, data) {
        data.forEach(item => {
            state.shops.push(item);
        });
    },
    SET_MENUS(state, data) {
        state.menus = data;
    },
    APPEND_MENUS(state, data) {
        data.forEach(item => {
            state.menus.push(item);
        });
    },
    SET_ITEMS(state, data) {
        state.items = data;
    },
    APPEND_ITEMS(state, data) {
        data.forEach(item => {
            state.items.push(item);
        });
    },

    SET_SHOPS_TOTAL(state, value) {
        state.shopsTotal = value;
    },
    SET_MENUS_TOTAL(state, value) {
        state.menusTotal = value;
    },
    SET_ITEMS_TOTAL(state, value) {
        state.itemsTotal = value;
    },

    SET_SHOW_SEARCH_RESULT(state, value) {
        state.showSearchResult = value;
    },
    SET_SEARCH_INPUT(state, value) {
        state.searchInput = value;
    },

    SET_SEARCH_FILTER(state, filter) {
        Object.assign(state.searchFilter, filter);
    },
    RESET_SEARCH_FILTER(state) {
        Object.assign(state.searchFilter, {
            delivery_type: undefined,
            pickup_method: 'P,E,D',
            sort_type: 1,
            shipping_sort_type: 8,
            status: false,
        });
    },
}