import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export const order = {
    state: {
        // PG 요청 데이터
        paymentData: {
            type: 'web',
            uuid: '',
            callback: window.location.origin + '/payment-result',
            payment: {
                pg: 'inicis',
                pay_method: 'card',
                delivery_charge: 0,
                delivery_charge_extra: 0,
                delivery_total: 0,
                member_coupon: undefined,
                total_order_coupon: 0,
                total_order_item_coupon_discount: 0,
                discount_total: 0,
                order_total: 0,
                order_total_value: 0,
                point: 0,
                paycoin_point: 0,
                point_total: 0,
                shipping_charge: 0,
                shipping_charge_extra: 0,
            },
            items: [],
        },
        delivery_info: {
            address: '',
            address_extra: '',
            memo: '',
        },
        shipping: {
            id: undefined,
            description: '',
        },
        order: {
            pickup_type: 'S',
            people: 1,
            pickup_at: undefined,
            comment: '',
            comment_extra: [],
            picker: {
                name: '',
                cp: '',
            },
        },
    },
    getters,
    mutations,
    actions,
}