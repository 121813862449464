import { getSearchedGoods, getShopsAroundMe } from '@/api/home'

export const actions = {
    async FETCH_SEARCH_SHOP({ state, rootState, commit }, { search, page }) {
        let result = undefined;
        try {
            result = await getShopsAroundMe({
                lat : rootState.geolocation.latitude,
                lng : rootState.geolocation.longitude,
                search: search,
                brand_code: state.searchFilter.brand_code,
                sort_type: state.searchFilter.sort_type,
                pickup_method: state.searchFilter.pickup_method,
                status: state.searchFilter.status ? 1 : undefined,
                is_active_discount_item: false,
                is_goods_view: false,
                category : undefined,
                page : page,
            }, rootState.user.access_token ? true : false);
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_SHOPS', result.data.data);
                commit('SET_SHOPS_TOTAL', result.data.count);
                commit('SET_SEARCH_SHOP_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_SHOPS_TOTAL', result.data.count);
                commit('SET_SEARCH_SHOP_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_SEARCH_SHOP', error);
        }

        return result;
    },

    async FETCH_SEARCH_MENU({ state, rootState, commit }, { search, page }) {
        let result = undefined;
        try {
            result = await getSearchedGoods({
                lat : rootState.geolocation.latitude,
                lng : rootState.geolocation.longitude,
                search: search,
                brand_code: state.searchFilter.brand_code,
                pickup_method: state.searchFilter.pickup_method,
                sort_type: state.searchFilter.sort_type,
                status: state.searchFilter.status ? 1 : undefined,
                page : page,
            }, rootState.user.access_token ? true : false);
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_MENUS', result.data.data);
                commit('SET_MENUS_TOTAL', result.data.count);
                commit('SET_SEARCH_MENU_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_MENUS_TOTAL', result.data.count);
                commit('SET_SEARCH_MENU_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_SEARCH_MENU', error);
        }

        return result;
    },

    async FETCH_SEARCH_SHIPPING({ state, rootState, commit }, { search, page }) {
        let result = undefined;
        try {
            result = await getSearchedGoods({
                lat : rootState.geolocation.latitude,
                lng : rootState.geolocation.longitude,
                search: search,
                sort_type: state.searchFilter.shipping_sort_type,
                status: 1,
                pickup_method: 'S',
                page : page,
            }, rootState.user.access_token ? true : false);
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('APPEND_ITEMS', result.data.data);
                commit('SET_ITEMS_TOTAL', result.data.count);
                commit('SET_SEARCH_ITEM_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' && result.data.data == undefined) {
                commit('SET_ITEMS_TOTAL', result.data.count);
                commit('SET_SEARCH_ITEM_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        }
        catch (error) {
            console.error('FETCH_SEARCH_SHIPPING', error);
        }

        return result;
    },
}