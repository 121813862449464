import { getOrderHistoryList, getOrderHistoryDetail } from '@/api/order'

export const actions = {
    async FETCH_PURCHASE_HISTORY({ state, commit }, { page }) {
        let result;
        try {
            result = await getOrderHistoryList({
                pickup_type: state.selectedTab,
                page: page,
                search: state.purchaseHistoryFilters.searchKeyword ? state.purchaseHistoryFilters.searchKeyword : undefined,
                date_after: state.purchaseHistoryFilters.fromDate ? state.purchaseHistoryFilters.fromDate : undefined,
                date_before: state.purchaseHistoryFilters.toDate ? state.purchaseHistoryFilters.toDate : undefined,
            });
            if(result.data.code == 'S0000' & result.data.data != undefined) {
                commit('APPEND_PURCHASED_LIST', result.data.data);
                commit('SET_PURCHASE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
            else if(result.data.code == 'S0000' & result.data.data == undefined) {
                commit('SET_PURCHASE_PAGINATION', {
                    page : result.data.page,
                    has_next : result.data.has_next,
                });
            }
        } catch (error) {
            console.error('getOrderHistoryListAsync', error)
        }

        return result;
    },
    async FETCH_PURCHASE_DETAIL({ commit }, { id }) {
        let result;
        try {
            // 구매내역 상세 가져오기
            result = await getOrderHistoryDetail(id);
            if(result.data.code == 'S0000' && result.data.data != undefined) {
                commit('SET_PURCHASED_ORDER', result.data.data);
            }
        } catch (error) {
            console.error('getOrderHistoryDetailAsync', error);
        }

        return result;
    },
    RESET_PURCHASED_LIST_FILTERS({ commit }){
        commit('SET_PURCHASED_LIST_FILTERS', {
            searchKeyword: '',
            fromDate: '',
            toDate: '',
        });
        commit('SET_IS_FILTERED_RESULT', false);
        commit('SET_SELECTED_DATE_FILTERS', 1);
    }
}