export const mutations = {
    APPEND_CART_COUPONS(state, data) {
        data.forEach(item => {
            state.cartCoupons.push(item);
        });
    },
    SET_CART_COUPONS(state, data) {
        state.cartCoupons = data;
    },
    APPEND_ORDER_COUPONS(state, data) {
        data.forEach(item => {
            state.orderCoupons.push(item);
        });
    },
    SET_ORDER_COUPONS(state, data) {
        state.orderCoupons = data;
    },

    SET_CART_TOTAL(state, value) {
        state.cartCouponTotal = value
    },
    SET_ORDER_TOTAL(state, value) {
        state.orderCouponTotal = value
    },

    SET_CART_PAGINATION(state, value) {
        Object.assign(state.cartPagination, value);
    },
    SET_ORDER_PAGINATION(state, value) {
        Object.assign(state.orderPagination, value);
    },
}